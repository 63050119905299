/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable no-console */
import React from 'react';
import axios from 'axios';
import Greeting from '../components/greeting';
import { Link, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import InputAdornment from '@mui/material/InputAdornment';

function AddEmployeeForm() {
  const [formData, setFormData] = React.useState({});
  const data = JSON.parse(localStorage.getItem('userdata'));
  const [loading, setLoading] = React.useState(false);
  const baseCountry = window.location.href.includes('lib') ? 'Liberia' : 'Sierra Leone';
  const [error, setError] = React.useState({});

  const emailValidationPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const currencyLabel = window.location.href.includes('lib') ? 'LRD' : 'LE';
  let countryCode = '';
  let staffidbase = "ES";

  const departments = [
    'After Sales',
    'Business Development',
    'Call Center',
    'Corporate Finance',
    'Finance',
    'IT',
    'Marketing',
    'Logistics',
    'Operations',
    'People & Culture',
    'Power Solution',
    'Sales',
    'Sales and Field Support',
    'Sales Support',
    'Shops',
    'Products',
    'Corporate sales'
  ];

  if (baseCountry === 'Liberia') {
    countryCode = '231';
  } else if (baseCountry === 'Sierra Leone') {
    countryCode = '232';
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    let fullName;
    if (name === 'Last Name') {
      fullName = value.trim() + ' ' + (formData['First Name'] ?? '');
    } else if (name === 'First Name') {
      fullName = (formData['Last Name'] ?? '') + ' ' + value.trim();
    } else {
      fullName = formData['Full Name'];
    }

    // Check if the input field is an email address and validate it using the emailValidationPattern
    if (name === 'Line Manager Email Address' || name === 'Budget Holder Email' || name === 'Employee Email Address') {
      if (!emailValidationPattern.test(value.trim())) {
        // If the email address is invalid, set the error state to true and display an error message
        setError((prevError) => ({ ...prevError, [name]: true, [name + 'Error']: 'Please enter a valid email address' }));
      } else {
        // If the email address is valid, set the error state to false and clear the error message
        setError((prevError) => ({ ...prevError, [name]: false, [name + 'Error']: '' }));
      }
    }

    // Concatenate the countryCode with the PhoneNo value
    if (name === 'PhoneNo') {
      const formattedPhoneNo = countryCode + value.trim();
      setFormData({
        ...formData,
        [name]: formattedPhoneNo,
        ['Full Name']: fullName.trim(),
        createdAt: new Date().toLocaleString(),
        ['Base Country']: baseCountry,
      });
    } else if (name === 'StaffID') {
      const formattedStaffID = staffidbase + value.trim();
      setFormData({
        ...formData,
        [name]: formattedStaffID,
        ['Full Name']: fullName.trim(),
        createdAt: new Date().toLocaleString(),
        ['Base Country']: baseCountry,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value.trim(),
        ['Full Name']: fullName.trim(),
        createdAt: new Date().toLocaleString(),
        ['Base Country']: baseCountry,
      });
    }
  };



  const API_URI = 'https://digital-forms-backend.onrender.com/addusers';
  const headers = { 'content-type': 'application/json' };
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,

    });
    await axios
      .post(API_URI, { ...formData, user: data }, headers)
      .then((response) => {
        setLoading(false);
        window.confirm(
          'You have successfully added the user.'
        );
        navigate('/viewusers');
      })
      .catch((err) => {
        console.log(err);
      });
  };



  return (
    <div className="formPage">
      <Greeting
        user=' Manager '
        text='Please add a new user to the database'
      />
      <form onSubmit={handleSubmit} className="form" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        <div className="column">
          <div className="row">
            <TextField
              label='Last Name'
              name='Last Name'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
            />
          </div>
          <div className="row">
            <TextField
              label='First Name'
              name='First Name'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
            />
          </div>

          <div className="row">
            <TextField
              label='Job Title'
              name='Job Title'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
            />
          </div>
          <div className="row">
            {/* dropdown of departments */}
            <TextField
              // label='Department'
              name='Department'
              required
              select
              SelectProps={{
                native: true,
              }}
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
            >
              <option value=''>Select Department</option>
              {departments.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          </div>
          <div className="row">
            <TextField
              label='Base Country'
              name='Base Country'
              required
              InputProps={{
                readOnly: true,
              }}
              variant='outlined'
              value={baseCountry}
              onChange={handleChange}
            />

          </div>
          <div className="row">
            <TextField
              label='Line Manager Name'
              name='Line Manager Name'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
            /></div>
          <div className="row">
            <TextField
              label='Line Manager Email Address'
              name='Line Manager Email Address'
              type="email"
              error={error['Line Manager Email Address']}
              helperText={error['Line Manager Email AddressError']}
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
            /></div>

          <div className="row">
            <TextField
              label='Budget Holder Name'
              name='Budget Holder'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
            /></div>

        </div>
        <div className="column">
          <div className="row">
            <TextField
              label='StaffID'
              name='StaffID'
              required
              InputProps={{
                startAdornment: <InputAdornment position="start">{staffidbase}</InputAdornment>,
              }}
              variant='outlined'
              onChange={handleChange}

            /></div>

          <div className="row">
            <TextField
              label='PhoneNo'
              name='PhoneNo'
              required

              variant='outlined'
              onChange={handleChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">{countryCode}</InputAdornment>,
              }}
            />
          </div>

          <div className="row">
            <TextField
              label='Budget Holder Email'
              name='Budget Holder Email'
              type="email"
              error={error['Budget Holder Email']}
              helperText={error['Budget Holder EmailError']}
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
            /></div>
          <div className="row">
            <TextField
              label='Employee Email Address'
              name='Employee Email Address'
              type="email"
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
            /></div>
          <div className="row">
            <TextField
              label='Accommodation'
              name='Accommodation'
              type="number"
              required
              InputProps={{
                startAdornment: <InputAdornment position="start">{currencyLabel}</InputAdornment>,
              }}
              variant='outlined'
              onChange={handleChange}
            /></div>
          <div className="row">
            <TextField
              type="number"
              label='Meals'
              name='Meals'
              required
              InputProps={{
                startAdornment: <InputAdornment position="start">{currencyLabel}</InputAdornment>,
              }}
              variant='outlined'
              onChange={handleChange}
            /></div>

          <div className="row">
            <TextField
              label='International Accommodation'
              name='InternationalAccommodation'
              type="number"
              required
              InputProps={{
                startAdornment: <InputAdornment position="start">USD</InputAdornment>,
              }}
              variant='outlined'
              onChange={handleChange}
            /></div>
          <div className="row">
            <TextField
              type="number"
              label='International Meals'
              name='InternationalMeals'
              required
              InputProps={{
                startAdornment: <InputAdornment position="start">USD</InputAdornment>,
              }}
              variant='outlined'
              onChange={handleChange}
            /></div>

        </div>

        <Button
          color='primary'
          variant='outlined'
          type='submit'
          size='large'
          className='submitButton'
        >
          {loading ? <CircularProgress color='inherit' /> : 'Add User'}
        </Button>
        <Link to='/viewusers'>
          <FontAwesomeIcon icon={faArrowLeft} /> Back to View Users
        </Link>
      </form>
      <style >{`
        .form {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0;
          margin-bottom: 20px;
        }

        .column {
          display: flex;
          flex-direction: column;
          width: 45%;
          
        }

        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0;
        }

        button {
          margin: 20px 0;
          padding: 10px;
          background-color: #022f8e;
          color: #fff;
          border: none;
          border-radius: 5;
          float: right;
        }
      `}</style>
    </div>
  );
}

export default AddEmployeeForm;
