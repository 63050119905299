import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo_cropped.png';

// eslint-disable-next-line react/prop-types
function Greeting({ user, text = 'Please Fill Out the details below' }) {
    return (
        <div className='greetContainer'>
            <Link to='/formselection'>
            <img src={logo} alt='' className='greetImage' />
            </Link>
            <h2 className='greeting'>
                Welcome,
                {user}. {text}
            </h2>
            {location.href.includes('reject') || location.href.includes('viewusers') || location.href.includes('addusers') || location.href.includes('edituser') ? null : (
                <h5 className='greetingLink'>
                    or
                    <Link to='/formselection'> select a different form</Link>
                </h5>
            )}
        </div>
    );
}

export default Greeting;
