/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Greeting from '../components/greeting';
import { Link, useNavigate } from 'react-router-dom';
import { BsCloudCheckFill } from 'react-icons/bs';
import { MdCloudUpload } from 'react-icons/md';
import {
    TextField,
    Button,
    CircularProgress,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { da } from 'date-fns/locale';

function UpdateEmployeeForm() {
    const [formData, setFormData] = React.useState({});

    const [loading, setLoading] = React.useState(false);
    const [itemsArray, setItemsArray] = React.useState([]);
    const [details, setDetails] = React.useState([]);
    const [invoiceDoc, setInvoice] = React.useState('#');


    let user = null;
    let userid = null;
    let Ememail = null;
    if (JSON.parse(localStorage.getItem('userdata')) !== null) {
        const { 'First Name': username, '_id': id, 'Employee Email Address': email } = JSON.parse(
            localStorage.getItem('userdata')
        );
        user = username;
        userid = id;
        Ememail = email;

    }



    // get the value of the url after edituser/
    const url = window.location.href;
    // get the id from the url starting from / and ending at ?
    const id = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('?'));
    console.log('id', id);
    // get the budgetcode from the url starting from ? to the end
    const bcode = url.substring(url.lastIndexOf('?') + 1, url.length);
    console.log('bcode1', bcode);
    // if bcode has %20 replace it with a space 
    if (bcode.includes('%20')) {
        bcode.replace('%20', ' ');
    }
    console.log('bcode2', bcode);


    const itemindex = url.substring(url.lastIndexOf('-') + 1, url.length);
    console.log(itemindex);

    // get the users in the database
    useEffect(() => {
        setLoading(true);
        axios.get(`https://digital-forms-backend.onrender.com/requests/pettycash/${userid}`)
            .then(response => {
                // console.log(response.data);
                const pcdata = response.data.filter((item) => item._id === id);
                // console.log(userid);
                const details = pcdata[0]['details'];
                setDetails(details);
                // console.log(details['items']);
                const items = details['items'];
                // set the itemsArray to the array of items

                const itemsArrays = [];// create an array to store the matching items
                // iterate over the items object and check each item's budgetcode property
                const itemsArrayss = [];
                for (const key in items) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (items.hasOwnProperty(key)) {
                        const item = items[key];
                        itemsArrays.push(item); // add the matching item to the array
                        if (item.name === bcode) {
                            itemsArrayss.push(item); // add the matching item to the array
                        }
                    }
                }
                console.log("Match:", itemsArrays);
                setItemsArray(itemsArrays);



                // get the item with the itemindex
                const item = itemsArrays.filter((item) => item.itemindex === itemindex);
                console.log("Item:", item);
                setLoading(false)


            })
            .catch(error => {
                console.log(error);
            });
    }, [id]);
    // console.log(details.currency)

    const navigate = useNavigate();

    const API_URI = `https://digital-forms-backend.onrender.com/oldupdatepc/${id}`;
    // const API_URI2 = `https://digital-forms-backend.onrender.com/updatepc/${id}`;
    const API_URI2 = `https://digital-forms-backend.onrender.com/oldupdatepc2/${id}`;

    const config1 = {
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
        },
    };
    const config2 = {
        headers: {
            'content-type': 'application/json',
        },
    };

    const submitWithAttachment = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = JSON.parse(localStorage.getItem('userdata'));
        const itemData = itemsArray.map((item) => ({
            budgetcode: item.budgetcode,
            name: item.name,
            description: item.description,
            amount: item.amount,
            cost: item.cost,
            total: item.total,
            retirement: item.retirement,
            balance: item.balance,
        }));

        const formData = new FormData();
        formData.append('totalamount', itemsArray.reduce((total, item) => total + item.total, 0));
        // console.log(formData);
        formData.append('requestid', id);
        formData.append('currency', details.currency);
        formData.append('approvalstatus', 'pending');
        formData.append('invoice', invoiceDoc); // Assuming invoiceDoc is a File object
        // append the items array as an object not as a string
        formData.append('items', JSON.stringify(itemData));
        formData.append('user', JSON.stringify(data));


        console.log(formData);
        await axios
            .post(API_URI, formData, config1)
            .then((response) => {
                setLoading(false);
                console.log(response);
                window.confirm(
                    'You have successfully updated the petty cash request.'
                );
                navigate('/viewpc');
            }
            )
            .catch((err) => {
                console.log(err);
            }
            );
    };


    const submitWithoutAttachment = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = JSON.parse(localStorage.getItem('userdata'));
        const itemData = itemsArray.map((item) => {
            return {
                budgetcode: item.budgetcode,
                name: item.name,
                description: item.description,
                amount: item.amount,
                cost: item.cost,
                total: item.total,
                retirement: item.retirement,
                balance: item.balance,
            };
        });
        const formData = {
            // calculate the total amount
            totalamount: itemsArray.reduce((total, item) => total + item.total, 0),
            requestid: id,
            currency: details.currency,
            approvalstatus: 'Pending',
            items: [...itemData],
            user: { ...data },

        };


        console.log(formData);
        await axios
            .post(API_URI2, { ...formData }, config2)
            .then((response) => {
                setLoading(false);
                console.log(response);
                window.confirm(
                    'You have successfully updated the petty cash request.'
                );
                navigate('/viewpc');
            }
            )
            .catch((err) => {
                console.log(err);
            }
            );


    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (invoiceDoc === '#') {
            submitWithoutAttachment(e);
        } else {
            submitWithAttachment(e);
        }
    };



    // console.log("Matching items:", itemsArray);
    // React.useEffect(() => {
    //     console.log(itemsArray);
    // }, [itemsArray, invoiceDoc]);

    // Handler for file input change



    return (
        <div className="formPage">
            <Greeting
                user={user}
                text='Please Enter the Retirement Amount'
            />
            <form
                className="form"
                style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
                onSubmit={handleSubmit}
            >
                <div className="table-wrapper">
                    <table className="fl-table">
                        <thead>
                            <tr>
                                <th>Budget Code</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Unit Cost</th>
                                <th>Total</th>
                                <th>Retirement</th>
                                <th>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* loop through itemsArray and put them to the table*/}
                            {itemsArray.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.budgetcode}</td>
                                    <td>{item.name}</td>
                                    <td>{item.description}</td>
                                    <td>{item.amount}</td>
                                    <td>{details.currency}{item.cost}</td>
                                    <td>{details.currency}{item.total}</td>
                                    <td>
                                        <input
                                            type="number"
                                            value={item.retirement}
                                            onChange={(e) => {
                                                const newRetirement = Math.max(0, e.target.value); // Ensure the new retirement value is non-negative
                                                const newTotal = item.amount * item.cost;
                                                const newBalance = newTotal - newRetirement;
                                                const updatedItem = { ...item, retirement: newRetirement, balance: newBalance };
                                                const updatedItemsArray = [...itemsArray];
                                                updatedItemsArray[index] = updatedItem;
                                                setItemsArray(updatedItemsArray);
                                            }}
                                        />
                                    </td>
                                    <td>{item.balance}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div>
                        <label htmlFor='invoices' className='fileupload'>
                            <p>{invoiceDoc.name || 'UPLOAD RECEIPT'}</p>{' '}
                            {invoiceDoc === '' ? (
                                <MdCloudUpload size={40} />
                            ) : (
                                <BsCloudCheckFill size={40} />
                            )}
                            <input
                                type='file'
                                hidden
                                // size='small'
                                name='invoices'
                                id='invoices'
                                onChange={(e) => {
                                    setInvoice(e.target.files[0]);
                                }}
                            />
                        </label>
                    </div>
                </div>
                <button type="submit">
                    {loading ? <CircularProgress color='inherit' /> : 'submit'}
                </button>
            </form>
            <style >{`
            *{
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
            }
            
            /* Table Styles */
            
            .table-wrapper{
                margin: 10px 70px 70px;
                box-shadow: 0px 35px 50px rgba( 0, 0, 0, 0.2 );
            }
            
            .fl-table {
                border-radius: 5px;
                font-size: 12px;
                font-weight: normal;
                border: none;
                border-collapse: collapse;
                width: 100%;
                max-width: 100%;
                white-space: nowrap;
                background-color: white;
            }
            
            .fl-table td, .fl-table th {
                text-align: center;
                padding: 8px;
            }
            
            .fl-table td {
                border-right: 1px solid #f8f8f8;
                font-size: 12px;
            }
            
            .fl-table thead th {
                color: #ffffff;
                background: #dcc57d;
            }
            
            
            .fl-table thead th:nth-child(odd) {
                color: #ffffff;
                background: #434169;
            }
            
            .fl-table tr:nth-child(even) {
                background: #F8F8F8;
            }
            
            /* Responsive */
            
            @media (max-width: 767px) {
                .fl-table {
                    display: block;
                    width: 100%;
                }
                .table-wrapper:before{
                    content: "Scroll horizontally >";
                    display: block;
                    text-align: right;
                    font-size: 11px;
                    color: white;
                    padding: 0 0 10px;
                }
                .fl-table thead, .fl-table tbody, .fl-table thead th {
                    display: block;
                }
                .fl-table thead th:last-child{
                    border-bottom: none;
                }
                .fl-table thead {
                    float: left;
                }
                .fl-table tbody {
                    width: auto;
                    position: relative;
                    overflow-x: auto;
                }
                .fl-table td, .fl-table th {
                    padding: 20px .625em .625em .625em;
                    height: 60px;
                    vertical-align: middle;
                    box-sizing: border-box;
                    overflow-x: hidden;
                    overflow-y: auto;
                    width: 120px;
                    font-size: 13px;
                    text-overflow: ellipsis;
                }
                .fl-table thead th {
                    text-align: left;
                    border-bottom: 1px solid #f7f7f9;
                }
                .fl-table tbody tr {
                    display: table-cell;
                }
                .fl-table tbody tr:nth-child(odd) {
                    background: none;
                }
                .fl-table tr:nth-child(even) {
                    background: transparent;
                }
                .fl-table tr td:nth-child(odd) {
                    background: #F8F8F8;
                    border-right: 1px solid #E6E4E4;
                }
                .fl-table tr td:nth-child(even) {
                    border-right: 1px solid #E6E4E4;
                }
                .fl-table tbody td {
                    display: block;
                    text-align: center;
                }
            }
            
          
          

          a{
            padding: 5px 10px;
          }
          
          .addUser {
            background-color: #022f8e;
            border: none;
            color: white;
            padding: 5px 10px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 14px;
            margin: 2px;
            cursor: pointer;
            float: right;
          }
          
          .search {
            margin: 20px;
          }

        #old

            .addret{
                background-color: #434169;
                color: #fff;
                border-radius: 5;
                height: 2rem;
              }
              
        .form {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0;
          margin-bottom: 20px;
        }

        .column {
          display: flex;
          flex-direction: column;
          width: 45%;
          
        }

        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0;
        }

        button {
          margin: 20px 0;
          padding: 10px;
          background-color: #022f8e;
          color: #fff;
          border: none;
          border-radius: 5
        }

        
      `}</style>
        </div>
    );
}

export default UpdateEmployeeForm;
