import React from 'react';
import axios from 'axios';
import {
    TextField,
    CircularProgress,
} from '@mui/material';
import Greeting from '../components/greeting';

// get the data from the database based on the get ID and display it in the form
const ViewRequest = () => {
    // get the data from https://digital-forms-backend.onrender.com/viewrequest?id and display it in the form
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);

    // get the id from the url
    const id = window.location.href.split('=').pop();


    React.useEffect(() => {
        setLoading(true);
        axios
            .get('https://digital-forms-backend.onrender.com/viewrequest?id=' + id)
            .then((res) => {
                setData(res.data);
                setLoading(false);
                // console.log(res.data);
            })
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <div>Something went wrong</div>;
    }

    if (!data) {
        return null;
    }


    return (
        <div className="formPage">
            <Greeting
                user=' Manager '
                text='Please let us know your reason for rejecting the request'
            />
            <div className="formContainer">
                <h2>View Request</h2>
                <form className="form">

                    <TextField
                        id="name"
                        label="Name"
                        variant="outlined"
                        value={data.user['Full Name']}
                        disabled
                    />

                    <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        value={data.email}
                        disabled
                    />

                    <TextField
                        id="department"
                        label="Department"
                        variant="outlined"
                        value={data.department}
                        disabled
                    />
                </form>
            </div>
        </div>


    );
};

export default ViewRequest;

