import React from 'react';
import axios from 'axios';
import {
    TextField,
    FormControl,
    InputLabel,
    Button,
    CircularProgress,
    Select,
    MenuItem,
} from '@mui/material';
import Greeting from '../components/greeting';
import { useNavigate } from 'react-router-dom';

const Reject = () => {
    const [reason, setReason] = React.useState('');
    const [other, setOther] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const reasons = [
        'Not Enough Information',
        'Wrong Department / Budget codes',
        'Too expensive',
        'Already ordered / invoiced',
        'Issue with supplier',
        'Other',
    ];
    const navigate = useNavigate();
    const TYPE = location.href.includes('pettycash') ? 'pettycash' : 'perdiem';
    const API_URI = `https://digital-forms-backend.onrender.com/rejectret/${TYPE}`;
    console.log(API_URI);
    // const API_URI = `https://digital-forms-backend.onrender.com/reject/${TYPE}`;
    // const headers = {
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    // };
    const ID = location.href.split('=')[1].split('&')[0];
    const FILE = location.href.split('=')[2];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        await axios
            .post(API_URI, { reason, other, ID, FILE, TYPE })
            .then((response) => {
                setLoading(false);
                console.log(response);
                window.confirm(
                    'You have denied this request. Relevant Parties will be notified.'
                );
                navigate('/reject/perdiembh');
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // get all the data from the /viewpettycash
    // const [data, setData] = React.useState([]);
    const [details, setDetails] = React.useState([]);
    const [approvalStatus, setApprovalStatus] = React.useState([]);
    const API_PD = `https://digital-forms-backend.onrender.com/viewperdiem`
    const API_PC = `https://digital-forms-backend.onrender.com/viewpettycash`
    React.useEffect(() => {
        if (TYPE === 'perdiem') {
            axios
                .get(API_PD)
                .then((response) => {

                    console.log(response.data);
                    setApprovalStatus(response.data.filter((el) => el._id === ID).map((el) => el.approvalStatus));
                    console.log(approvalStatus[0]);

                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            axios
                .get(API_PC)
                .then((response) => {
                    // setData(response.data);
                    // setData to a specify id 
                    // setData(response.data.filter((el) => el._id === ID));
                    setDetails(response.data.filter((el) => el._id === ID).map((el) => el.details));
                    setApprovalStatus(response.data.filter((el) => el._id === ID).map((el) => el.details.approvalStatus));
                    // console.log(approvalStatus[0]);

                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);


    console.log(details);
    console.log(approvalStatus[0]);
    // console.log(approvalStatus);
    // console.log(approvalStatus[0] === 'approved');
    //    check the approvalStatus from data


    if (approvalStatus[0] === 'approvedretirement') {
        return (
            <p>This Request has already been Approved</p>
        )
    } else if (approvalStatus[0] === 'rejectedretirement') {
        return (
            <p>This Request has already been Rejected</p>
        )
    } else if (approvalStatus[0] === 'approved') {
        return (
            <div className='reject'>
                <Greeting
                    user=' Manager '
                    text='Please let us know your reason for rejecting the request'
                />
                <form onSubmit={handleSubmit}>
                    <FormControl sx={{ m: 1, minWidth: 200 }} size='small'>
                        <InputLabel id='demo-simple-select-label'>
                            Reason?
                        </InputLabel>
                        <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={reason}
                            placeholder='Select a reson for rejection'
                            label='Reason '
                            name='reason'
                            required
                            onChange={(e) => setReason(e.target.value)}
                        >
                            {reasons.map((el, i) => (
                                <MenuItem required key={i} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Select>
                        {reason === 'Other' ? (
                            <TextField
                                type='text'
                                size='large'
                                label='Please specify'
                                multiline
                                minRows={3}
                                name='other_specify'
                                className='textInput'
                                onChange={(e) => setOther(e.target.value)}
                            />
                        ) : null}
                    </FormControl>
                    <Button
                        color='primary'
                        variant='outlined'
                        type='submit'
                        size='large'
                        className='submitButton'
                    >
                        {loading ? <CircularProgress color='inherit' /> : 'submit'}
                    </Button>
                </form>
            </div>
        );
    }


};

export default Reject;
