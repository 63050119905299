import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Home from './components/Home';
import jwt_decode from 'jwt-decode';

import './App.css';

function App() {
    const [loading, setLoading] = React.useState(false);
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
    const navigate = useNavigate();
    const [staffId, setStaffId] = React.useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [status, setStatus] = useState('');
    const [verificationSid, setVerificationSid] = useState('');

    React.useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use(config => {
            setLoading(true);
            return config;
        });

        const responseInterceptor = axios.interceptors.response.use(response => {
            setLoading(false);
            return response;
        });

        // Clean up interceptors
        return () => {
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, []);

    const handleSendOTP = async (phoneNumber) => {
        setLoading(true);
        try {
            const response = await fetch('https://digital-forms-backend.onrender.com/send-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phoneNumber,
                }),
            });

            const data = await response.json();
            console.log(data);

            if (response.ok) {
                setStatus(`OTP  sent to ${phoneNumber.slice(0, 4)}xxxxxx${phoneNumber.slice(-2)}`);
                setVerificationSid(data.verificationSid);
                setLoading(false);
            } else {
                // setStatus(`Failed to send OTP: ${data.message}`);
                console.log(data.message);
            }
        } catch (error) {
            setStatus('An error occurred');
            console.error(error);
        }
    };

    const handleVerifyOTP = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://digital-forms-backend.onrender.com/verify-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    //   add + to the phone number
                    phoneNumber: '+' + phoneNumber,
                    otp,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setStatus('OTP verification successful');
                setLoading(false);
                navigate('/formselection');
                // Perform the login action here
            } else {
                setStatus(`Invalid OTP: ${data.message}`);
                setLoading(false);
            }
        } catch (error) {
            setStatus('An error occurred');
            console.error(error);
        }
    };
    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        // join the array into a string
        const otpString = newOtp.join('');
        // console.log(otpString);
        setOtp(otpString);
        // Automatically focus on the next input box
        if (index < 5 && value !== '') {
            const nextInput = document.getElementById(`otpInput-${index + 1}`);
            if (nextInput) {
                nextInput.focus();
            }
        }

    };

    const handleStaffIdChange = (event) => {
        // set to upper case
        event.target.value = event.target.value.toUpperCase();
        setStaffId(event.target.value);
    };

    const handleLoginWithStaffId = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://digital-forms-backend.onrender.com/usersotp?staffid=ES${staffId}`);
            const data = await response.json();


            if (response.ok) {
                // const phoneNo = data.PhoneNo;
                window.localStorage.setItem('userdata', JSON.stringify(data));
                // setPhoneNumber(phoneNo);
                // get the data from the local storage
                const userData = JSON.parse(window.localStorage.getItem('userdata'));
                console.log(userData.PhoneNo);
                setPhoneNumber(userData.PhoneNo);
                // add + to the phone number
                handleSendOTP('+' + userData.PhoneNo);
                // setLoading(false);
            } else {
                setStatus(`Failed to retrieve phone number: ${data.message}`);
                console.log(data.message)
                setLoading(false);
            }
        } catch (error) {
            setStatus('Invalid Staff ID');
            setLoading(false);
            console.error(error);
        }
    };

    const responseGoogle = async (res) => {
        console.time("GoogleLoginTime"); // Start the timer

        try {
            const details = jwt_decode(res.credential);
            const email = details.email;

            const response = await axios.get(`https://digital-forms-backend.onrender.com/users?email=${email}`);
            window.localStorage.setItem('userdata', JSON.stringify(response.data));
            navigate('/formselection');
        } catch (error) {
            console.error('Error during Google login:', error);
            // Handle error (e.g., show error message)
        } finally {
            console.timeEnd("GoogleLoginTime"); // Stop the timer and log the time
        }
    };

    // axios.interceptors.request.use(function (config) {
    //     setLoading(true);
    //     return config;
    // });

    // axios.interceptors.response.use(function (response) {
    //     setLoading(false);
    //     return response;
    // });

    return (
        <div className="App">
            <Home
                res={responseGoogle}
                id={CLIENT_ID}
                loading={loading}
                staffId={staffId}
                handleStaffIdChange={handleStaffIdChange}
                handleLoginWithStaffId={handleLoginWithStaffId}
                handleSendOTP={handleSendOTP}
                handleVerifyOTP={handleVerifyOTP}
                otp={otp}
                setOtp={setOtp}
                status={status}
                verificationSid={verificationSid}
                handleOtpChange={handleOtpChange}

            />
        </div>
    );
}

export default App;
