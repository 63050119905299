/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable no-console */
import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/pettycash.css';
import { BsFillPlusCircleFill, BsCloudCheckFill, BsFillDashCircleFill } from 'react-icons/bs';
import { MdCloudUpload } from 'react-icons/md';
import {
    TextField,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Button,
    CircularProgress,
    FormLabel,
} from '@mui/material';
import Greeting from '../components/greeting';
import Item from '../components/Itemret';
// import { departments } from '../components/budgetCodes';

function ExpenseAdvance() {
    const [itemsData] = React.useState({});
    // const [itemsTotal, setItemsTotal] = React.useState(100);
    const [submitData, setSubmitData] = React.useState({});
    // eslint-disable-next-line react/jsx-key
    const [list, setList] = React.useState([<Item count={0} />]);
    const [bankDetails] = React.useState({});
    // const [currency] = React.useState('USD');
    const [loading, setLoading] = React.useState(false);
    // const [department] = React.useState('Select Department');
    const [invoiceDoc, setInvoice] = React.useState('#');
    const [details, setDetails] = React.useState([]);
    const [itemsArray, setItemsArray] = React.useState([]);

    const TOTAL = React.useMemo(() => [], []);
    // const currencyLabel = window.location.href.includes('lib') ? 'LRD' : 'LE';

    const navigate = useNavigate();
    const data = JSON.parse(localStorage.getItem('userdata'));
    let user = null;
    let userid = null;
    if (JSON.parse(localStorage.getItem('userdata')) !== null) {
        const { 'First Name': username, '_id': id } = JSON.parse(
            localStorage.getItem('userdata')
        );
        user = username;
        userid = id;
        console.log(user)

    }
    // axios.interceptors.request.use(function (config) {
    //   // spinning start to show/
    //   setLoading(true);
    //   return config;
    // });

    // axios.interceptors.response.use(function (response) {
    //   // spinning hide
    //   setLoading(false);
    //   return response;
    // });
    // get the value of the url after edituser/
    const url = window.location.href;
    // get the id from the url starting from / and ending at ?
    const id = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('?'));
    // console.log(id);
    // get the budgetcode from the url starting from ? and ending at the end of the url
    const bcode = url.substring(url.lastIndexOf('?') + 1, url.lastIndexOf('-'));
    // remove the whitespace from the budgetcode
    bcode.replace(/%20/g, ' ');
    // console.log(bcode2);


    const itemindex = url.substring(url.lastIndexOf('-') + 1, url.length);

    React.useEffect(() => {
        setLoading(true);
        axios.get(`https://digital-forms-backend.onrender.com/requests/pettycash/${userid}`)
            .then(response => {
                // console.log(response.data);
                const pcdata = response.data.filter((item) => item._id === id);
                // console.log(userid);
                const details = pcdata[0]['details'];
                setDetails(details);
                // console.log(details['items']);
                const items = details['items'];
                // set the itemsArray to the array of items

                const itemsArrays = [];// create an array to store the matching items
                // iterate over the items object and check each item's budgetcode property
                const itemsArrayss = [];
                for (const key in items) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (items.hasOwnProperty(key)) {
                        const item = items[key];
                        itemsArrays.push(item); // add the matching item to the array
                        if (item.name === bcode) {
                            itemsArrayss.push(item); // add the matching item to the array
                        }
                    }
                }
                // console.log("Match:", itemsArrays);
                setItemsArray(itemsArrays);



                // get the item with the itemindex
                const item = itemsArrays.filter((item) => item.itemindex === itemindex);
                console.log("Item:", item);
                setLoading(false);

            })
            .catch(error => {
                console.log(error);
            });
    }, [id]);
    console.log(details);
    console.log(itemsArray);

    const addOneToList = (e) => {
        e.preventDefault();
        // eslint-disable-next-line react/jsx-key
        setList([...list, <Item />]);
    };
    // remove the last Item from the list 

    const removeLastItem = (e) => {
        e.preventDefault()
        let newList = [...list.pop()]
        setList([...newList])
    }


    // const handleBankChange = (e) => {
    //     setBankDetails({
    //         ...bankDetails,
    //         [e.target.name]: e.target.value,
    //         currency,
    //         department,
    //         approvalStatus: 'pending',
    //         createdAt: new Date().toLocaleString(),
    //         urlname: 'expenseAdvance',
    //         finaltotal: itemsTotal,
    //     });
    // };


    const SUBMIT_URI1 = 'https://digital-forms-backend.onrender.com/requests/pettycash';
    const SUBMIT_URI2 = `https://digital-forms-backend.onrender.com/oldupdatepc/${id}/${itemindex}`;


    const config1 = {
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
        },
    };

    const config2 = {
        headers: {
            'content-type': 'application/json',
        },
    };
    const submitWithoutAttachment = async () => {

        await axios
            .post(
                SUBMIT_URI2,
                { details: { ...submitData }, user: data },
                config2
            )
            .then((response) => {
                window.confirm(
                    'Your Request was successful. You Line Manager will receive the details'
                );
                setLoading(false);
                navigate('/formselection');
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });

        console.log({ ...submitData, user: data });
    };

    const submitWithAttachment = async () => {
        const formData = new FormData();
        formData.append('invoice', invoiceDoc);
        formData.append('details', JSON.stringify(submitData));
        formData.append('user', JSON.stringify(data));

        await axios
            .post(SUBMIT_URI1, formData, config1)
            // eslint-disable-next-line no-unused-vars
            .then((response) => {
                // eslint-disable-next-line no-alert
                window.confirm(
                    'Your Request was successful. You Line Manager will receive the details'
                );
                setLoading(false);
                navigate('/formselection');
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        // checkTotal();

        if (invoiceDoc === '#') {
            submitWithoutAttachment();
        }
        if (invoiceDoc !== '#') {
            submitWithAttachment();
        }


    };
    // handelChange in the form 
    const handelChange = (e) => {
        e.preventDefault();
        setItemsArray({
            ...itemsArray,
            [e.target.name]: e.target.value,
        });
    };



    React.useEffect(() => {
        delete itemsData[''];
        Object.keys(itemsData)
            .filter((key) => !itemsData[key].cost || !itemsData[key].amount)
            .forEach((key) => delete itemsData[key]);

        setSubmitData({
            ...bankDetails,
            items: { ...itemsData },
        });

        for (const el in itemsData) {
            const index = parseInt(el);
            TOTAL[index] = itemsData[el].total;
        }
        // setItemsTotal(TOTAL.reduce((x, y) => x + y, 0));
    }, [itemsData, bankDetails, TOTAL, invoiceDoc]);


    const totalitems = itemsArray.length;
    let finaltotal = 0;
    const forms = [];
    for (let i = 0; i < totalitems;) {
        const { budgetcode, name, description, cost, amount, total } = itemsArray[i];
        forms.push(
            <div className="elDiv">
                <FormControl sx={{ m: 1, minWidth: 200 }} size='small'>
                    <FormLabel id='demo-simple-select-label'>
                        Budget Code?
                    </FormLabel>
                    <TextField
                        id='demo-simple-select'
                        value={budgetcode}
                        name='budgetcode'
                        required
                        // onChange={setBudgetCode(itemsArray[i].budgetcode)}
                        onChange={handelChange}
                    />
                </FormControl>

                <TextField
                    className="textInput"
                    required
                    type="text"
                    size="small"
                    id="outlined-required"
                    label="Item Name"
                    autoComplete="off"
                    value={name}
                    // name={`item${count}`}
                    // onChange={setItemName(itemsArray[i].name)}
                    onChange={handelChange}

                />
                <TextField
                    className="textInput"
                    required
                    type="text"
                    size="small"
                    id="outlined-required"
                    label="description"
                    multiline
                    minRows={2}
                    // name={`item${count}`}
                    // onChange={setInfo(itemsArray[i].description)}
                    value={description}
                    onChange={handelChange}
                />
                <TextField
                    className="textInput"
                    required
                    type="number"
                    size="small"
                    label="Unit Cost"
                    // onChange={setCost(itemsArray[i].cost)}
                    // name={`cost${count}`}
                    value={cost}
                    onChange={handelChange}
                />

                <TextField
                    type="number"
                    className="textInput"
                    size="small"
                    label="Quantity"
                    value={amount}
                    // onChange={setAmount(itemsArray[i].amount)}
                    // name={`amount${count}`}
                    onChange={handelChange}

                />
                <TextField
                    className="textInput"
                    type="number"
                    size="small"
                    label="Total"
                    value={total}
                    onChange={handelChange}
                />
                <TextField
                    className="textInput"
                    type="number"
                    size="small"
                    label="Retiement"
                    onChange={handelChange}
                // value={retiement}
                // value={retirement}
                />
            </div>
        )
        i++;
        finaltotal += total;

    }


    return (
        <div className='pettycashForm'>
            <Greeting user={data['First Name']} />
            {/* <div className="flex-container">
                <Link to='/viewpc'>
                    <button className='addret'>Add Retirement</button>
                </Link>
            </div> */}
            <form onSubmit={handleSubmit}>


                {/* currency selection section */}
                {/* budget code / Department selection section  */}
                <section className='requestSection'>
                    {forms}
                    <footer>
                        <span>
                            <FormControl>
                                <InputLabel htmlFor='outlined-adornment-amount'>
                                    Request Total
                                </InputLabel>

                                <OutlinedInput
                                    value={finaltotal}
                                    name='totalclaim'
                                    startAdornment={
                                        <InputAdornment position='start'>
                                            {details.currency}
                                        </InputAdornment>
                                    }
                                    label='Amount'
                                />
                            </FormControl>
                        </span>
                        <button
                            type='button'
                            disabled={list.length >= 15}
                            className='actionButton addButton'
                            onClick={addOneToList}
                        >
                            <BsFillPlusCircleFill />
                        </button>
                        <button
                            disabled={list.length < 2 ? true : false}
                            className='actionButton removeButton'
                            onClick={removeLastItem}>
                            <BsFillDashCircleFill />
                        </button>
                    </footer>
                </section>

                <div>
                    <label htmlFor='invoices' className='fileupload'>
                        <p>{invoiceDoc.name || 'UPLOAD INVOICE'}</p>{' '}
                        {invoiceDoc === '' ? (
                            <MdCloudUpload size={40} />
                        ) : (
                            <BsCloudCheckFill size={40} />
                        )}
                        <input
                            type='file'
                            hidden
                            // size='small'
                            name='invoices'
                            id='invoices'
                            onChange={(e) => {
                                setInvoice(e.target.files[0]);
                            }}
                        />
                    </label>
                </div>

                <Button
                    color='primary'
                    variant='outlined'
                    type='submit'
                    size='large'
                    className='submitButton'
                >
                    {loading ? <CircularProgress color='inherit' /> : 'submit'}
                </Button>
            </form>
            <style >{`
        .flex-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 1rem;
            
          }
          .addret{
            background-color: #434169;
            color: #fff;
            border-radius: 5;
            height: 2rem;
          }
      `}</style>
        </div>
    );
}

export default ExpenseAdvance;
