import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import FormSelection from './pages/FormSelection';
import PerDiem from './pages/perdiem';
import PettyCash from './pages/pettycash';
import Reject from './pages/reject';
import ViewUsers from './pages/viewUsers';
import View from './pages/view';
import AddUsers from './pages/AddUser';
import EditUsers from './pages/editUser';
import ViewPc from './pages/viewPc';
import AddRet from './pages/addRet';
import RejectPc from './pages/rejectpc';
import ExpenseAdvance from './pages/expenseAdvance';
import OldaddRet from './pages/oldaddRet';
import Otp from './pages/otp';
import RejectBh from './pages/rejectbh';
import RejectPdpc from './pages/rejectpdpc';
import Rejectf from './pages/rejectf';
import Rejectpcret from './pages/rejectpcret';
import RequestSummary from './pages/requestSummary';
import ViewTp from './pages/viewTp';
import AddTp from './pages/addTp';
import EditTp from './pages/editTp';
// import Vehicle from './pages/vehicle'
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<App />} />
                <Route path='/formselection' element={<FormSelection />} />
                <Route path='/perdiem' element={<PerDiem />} />
                <Route path='/pettycash' element={<PettyCash />} />
                <Route path='/reject/*' element={<Reject />} />
                <Route path='/viewusers' element={<ViewUsers />} />
                <Route path='/view' element={<View />} />
                <Route path='/addusers' element={<AddUsers />} />
                <Route path='/edituser/:id' element={<EditUsers />} />
                <Route path='/viewpc' element={<ViewPc />} />
                <Route path='/addret/:id' element={<AddRet />} />
                <Route path='/rejectpc/*' element={<RejectPc />} />
                <Route path='/expenseadvance' element={<ExpenseAdvance />} />
                <Route path='/oldaddret/:id' element={<OldaddRet />} />
                <Route path='/otp' element={<Otp />} />
                <Route path='/rejectbh/*' element={<RejectBh />} />
                <Route path='/rejectpdpc/*' element={<RejectPdpc />} />
                <Route path='/rejectf/*' element={<Rejectf />} />
                <Route path='/rejectpcret/*' element={<Rejectpcret />} />
                <Route path='/requestsummary' element={<RequestSummary />} />
                <Route path='/viewtp' element={<ViewTp />} />
                <Route path='/addtp' element={<AddTp />} />
                <Route path='/edittp/:id' element={<EditTp />} />
                {/* <Route path='/reject/pettycash' element={<Reject />} /> */}
                {/* <Route path='/vehicle' element={<Vehicle />} /> */}
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
