/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Greeting from '../components/greeting';
import { Link, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import InputAdornment from '@mui/material/InputAdornment';

function UpdateEmployeeForm() {
  const [formData, setFormData] = React.useState({});
  const data = JSON.parse(localStorage.getItem('userdata'));
  const [loading, setLoading] = React.useState(false);

  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [department, setDepartment] = useState('');
  const [baseCountry, setBaseCountry] = useState('');
  const [lineManagerName, setLineManagerName] = useState('');
  const [lineManagerEmail, setLineManagerEmail] = useState('');
  const [budgetHolder, setBudgetHolder] = useState('');
  const [budgetHolderEmail, setBudgetHolderEmail] = useState('');
  const [employeeEmail, setEmployeeEmail] = useState('');
  const [staffId, setStaffId] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [accommodation, setAccommodation] = useState('');
  const [meals, setMeals] = useState('');
  const [internationalaccommodation, setInternationalAccommodation] = useState('');
  const [internationalmeals, setInternationalMeals] = useState('');

  const departments = [
    'After Sales',
    'Business Development',
    'Call Center',
    'Corporate Finance',
    'Finance',
    'IT',
    'Marketing',
    'Logistics',
    'Operations',
    'People & Culture',
    'Power Solution',
    'Sales',
    'Sales and Field Support',
    'Sales Support',
    'Shops',
    'Products',
    'Corporate sales'
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'Last Name':
        setLastName(value);
        break;
      case 'First Name':
        setFirstName(value);
        break;
      case 'Job Title':
        setJobTitle(value);
        break;
      case 'Department':
        setDepartment(value);
        break;
      case 'Base Country':
        setBaseCountry(value);
        break;
      case 'Line Manager Name':
        setLineManagerName(value);
        break;
      case 'Line Manager Email Address':
        setLineManagerEmail(value);
        break;
      case 'Budget Holder':
        setBudgetHolder(value);
        break;
      case 'Budget Holder Email':
        setBudgetHolderEmail(value);
        break;
      case 'Employee Email Address':
        setEmployeeEmail(value);
        break;
      case 'StaffID':
        setStaffId(value);
        break;
      case 'PhoneNo':
        setPhoneNo(value);
        break;
      case 'Accommodation':
        setAccommodation(value);
        break;
      case 'Meals':
        setMeals(value);
        break;
      case 'InternationalAccommodation':
        setInternationalAccommodation(value);
        break;
      case 'InternationalMeals':
        setInternationalMeals(value);
        break;
      default:
        break;
    }
  };


  // get the value of the url after edituser/
  const url = window.location.href;
  const id = url.substring(url.lastIndexOf('/') + 1);
  // console.log(id);

  // get the users in the database
  useEffect(() => {
    setLoading(true);
    axios.get('https://digital-forms-backend.onrender.com/allusers')
      .then(response => {
        // get the user data from the database that matches the id in the url
        const user = response.data.filter((user) => user._id === id);
        setLastName(user[0]['Last Name'])
        setFirstName(user[0]['First Name'])
        setJobTitle(user[0]['Job Title'])
        setDepartment(user[0]['Department'])
        setBaseCountry(user[0]['Base Country'])
        setLineManagerName(user[0]['Line Manager Name'])
        setLineManagerEmail(user[0]['Line Manager Email Address'])
        setBudgetHolder(user[0]['Budget Holder'])
        setBudgetHolderEmail(user[0]['Budget Holder Email'])
        setEmployeeEmail(user[0]['Employee Email Address'])
        setStaffId(user[0]['StaffID'])
        setPhoneNo(user[0]['PhoneNo'])
        setAccommodation(user[0]['Accommodation'])
        setMeals(user[0]['Meals'])
        setInternationalAccommodation(user[0]['InternationalAccommodation'])
        setInternationalMeals(user[0]['InternationalMeals'])
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  }, [id]);





  const API_URI = `https://digital-forms-backend.onrender.com/updateuser/${id}`;
  // console.log(API_URI);
  const headers = { 'content-type': 'application/json' };
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      'Last Name': lastName,
      'First Name': firstName,
      'Job Title': jobTitle,
      'Department': department,
      'Base Country': baseCountry,
      'Line Manager Name': lineManagerName,
      'Line Manager Email Address': lineManagerEmail,
      'Budget Holder': budgetHolder,
      'Budget Holder Email': budgetHolderEmail,
      'Employee Email Address': employeeEmail,
      'StaffID': staffId,
      'PhoneNo': phoneNo,
      'Accommodation': accommodation,
      'Meals': meals,
      'InternationalAccommodation': internationalaccommodation,
      'InternationalMeals': internationalmeals,
    };
    console.log(data);
    const headers = { 'content-type': 'application/json' };
    try {
      const response = await axios.put(API_URI, data, headers);
      console.log(response.data);
      window.confirm('You have successfully updated the user.');
      navigate('/viewusers');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };




  return (
    <div className="formPage">
      <Greeting
        user=' Manager '
        text='Please Update the user data'
      />
      <form onSubmit={handleSubmit} className="form" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        <div className="column">
          <div className="row">
            <TextField
              label='Last Name'
              name='Last Name'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
          </div>
          <div className="row">
            <TextField
              label='First Name'
              name='First Name'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
          </div>

          <div className="row">
            <TextField
              label='Job Title'
              name='Job Title'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
              value={jobTitle}
            />
          </div>
          <div className="row">
            <TextField
              // label='Department'
              name='Department'
              required
              select
              SelectProps={{
                native: true,
              }}
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
              value={department}
            >
              <option value=''>Select Department</option>
              {departments.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>

          </div>
          <div className="row">
            <TextField
              label='Base Country'
              name='Base Country'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
              value={baseCountry}
            />

          </div>
          <div className="row">
            <TextField
              label='Line Manager Name'
              name='Line Manager Name'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
              value={lineManagerName}
            /></div>
          <div className="row">
            <TextField
              label='Line Manager Email Address'
              name='Line Manager Email Address'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
              value={lineManagerEmail}
            /></div>
          <div className="row">
            <TextField
              label='Budget Holder'
              name='Budget Holder'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
              value={budgetHolder}
            /></div>


        </div>
        <div className="column">

          <div className="row">
            <TextField
              label='Budget Holder Email'
              name='Budget Holder Email'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
              value={budgetHolderEmail}
            /></div>

          <div className="row">
            <TextField
              label='Employee Email Address'
              name='Employee Email Address'
              // required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
              value={employeeEmail}
            /></div>

          <div className="row">
            <TextField
              label='StaffID'
              name='StaffID'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
              value={staffId}
            /></div>

          <div className="row">
            <TextField
              label='PhoneNo'
              name='PhoneNo'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
              value={phoneNo}
            /></div>

          <div className="row">
            <TextField
              label='Accommodation'
              name='Accommodation'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
              value={accommodation}
            /></div>
          <div className="row">
            <TextField
              label='Meals'
              name='Meals'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={(e) => setMeals(e.target.value)}
              value={meals}
            /></div>
          <div className="row">
            <TextField
              label='International Accommodation'
              name='InternationalAccommodation'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={handleChange}
              value={internationalaccommodation}
            /></div>
          {/* add */}
          <div className="row">
            <TextField
              label='International Meals'
              name='International Meals'
              required
              InputProps={{
                readOnly: false,
              }}
              variant='outlined'
              onChange={(e) => setInternationalMeals(e.target.value)}
              value={internationalmeals}
            /></div>

        </div>

        <Button
          color='primary'
          variant='outlined'
          type='submit'
          size='large'
          className='submitButton'
        >
          {loading ? <CircularProgress color='inherit' /> : 'Update User'}
        </Button>

        <Link to='/viewusers'>
          <FontAwesomeIcon icon={faArrowLeft} /> Back to View Users
        </Link>

      </form>
      <style >{`
        .form {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0;
          margin-bottom: 20px;
        }

        .column {
          display: flex;
          flex-direction: column;
          width: 45%;
          
        }

        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0;
        }

        button {
          margin: 20px 0;
          padding: 10px;
          background-color: #022f8e;
          color: #fff;
          border: none;
          border-radius: 5
        }

        
      `}</style>
    </div>
  );
}

export default UpdateEmployeeForm;
