import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Greeting from '../components/greeting';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


function ViewTp() {
    const [transportData, setTransportData] = useState([]);
    // const [searchQuery, setSearchQuery] = useState('');
    // const [selectedRow, setSelectedRow] = useState(null);


    // const handleFullNameClick = (userId) => {
    //     setSelectedRow(userId);
    // };

    let user = null;
    let department = null;
    if (JSON.parse(localStorage.getItem('userdata')) !== null) {
        const { 'First Name': username, Department } = JSON.parse(
            localStorage.getItem('userdata')
        );
        user = username;
        department = Department;
    }
    // check the url if it has sl in it
    // if it does, then filter the users by Base Country and display them
    // if it doesn't, then display all the users


    // useEffect(() => {
    //     axios.get('https://digital-forms-backend.onrender.com/allusers')

    //         .then(response => {
    //             // filter the users by Base Country
    //             if (sl) {
    //                 const filteredUsers = response.data.filter(user => user['Base Country'] === 'Sierra Leone');
    //                 setUsers(filteredUsers);
    //             } else if (lib) {
    //                 const filteredUsers = response.data.filter(user => user['Base Country'] === 'Liberia');
    //                 setUsers(filteredUsers);
    //             }
    //             // console.log(response.data);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }, []);

    useEffect(() => {
        // setLoading(true);
        axios.get('https://digital-forms-backend.onrender.com/alltp')
            .then(response => {
                setTransportData(response.data);
                // setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data: ", error);

            });
    }, []);

    const navigate = useNavigate();
    const handleDeleteUser = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this Transportation Data?');
        if (confirmDelete) {
            await axios.delete(`https://digital-forms-backend.onrender.com/deletetp/${id}`);
            window.confirm('You have successfully deleted the user.');
            // reload the page
            window.location.reload();
            navigate('/viewtp');

        } else {
            return;
        }

    };






    if (user && department === "IT" || department === "People & Culture") {

        return (
            <div>
                <Greeting
                    user='Manager'
                    text='Transportation Data'
                />

                <div className='search'>
                    {/* <input type="text" placeholder="Search" value={searchQuery} onChange={handleSearch} /> */}

                    <Link to='/addtp'>
                        <button className='addUser'>Add Transportation</button>
                    </Link>
                </div>


                <div className="table-wrapper">
                    <table className="fl-table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>From Location</th>
                                <th>To Location</th>
                                <th>Oneway Amount</th>
                                <th>Twoway Amount</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transportData.map((data, index) => (
                                <tr key={data._id}>
                                    <td>{index + 1}</td>
                                    <td>{data.FromLocation}</td>
                                    <td>{data.ToLocation}</td>
                                    <td>{data.oneamount}</td>
                                    <td>{data.oneamount * 2} </td>
                                    <td>
                                        <Link to={`/edittp/${data._id}`}>
                                            <a href="/edittp" className="btn border-shadow update">
                                                <span className="text-gradient"><FontAwesomeIcon icon={faPencilAlt} /></span>
                                            </a>
                                        </Link>
                                        <button className="btn border-shadow delete" data-id={data._id} onClick={() => handleDeleteUser(data._id)}>
                                            <span className="text-gradient"><FontAwesomeIcon icon={faTimes} /></span>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <style >{`
                *{
                    box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                }
                
        
                
                /* Table Styles */
                
                .table-wrapper{
                    margin: 11px 20px 70px;
                    box-shadow: 0px 35px 50px rgba( 0, 0, 0, 0.2 );
                    overflow-x: auto;
                    
                }
                
                .fl-table {
                    border-radius: 5px;
                    font-size: 12px;
                    font-weight: normal;
                    border: none;
                    border-collapse: collapse;
                    width: 100%;
                    max-width: 100%;
                    white-space: nowrap;
                    background-color: white;
                    overflow-x: auto;
                }
                
                .fl-table td, .fl-table th {
                    text-align: center;
                    padding: 8px;
                }
                
                .fl-table td {
                    border-right: 1px solid #f8f8f8;
                    font-size: 12px;
                }
                
                .fl-table thead th {
                    color: #ffffff;
                    background: #dcc57d;
                }
                
                
                .fl-table thead th:nth-child(odd) {
                    color: #ffffff;
                    background: #434169;
                }
                
                /* add a fix horizontal scroll */
                .fl-table tbody {
                    overflow-y: auto;
                    overflow-x: hidden;
                }
                
                
                /* Responsive */
                
                @media (max-width: 767px) {
                    .fl-table {
                        display: block;
                        width: 100%;
                    }
                    .table-wrapper:before{
                        content: "Scroll horizontally >";
                        display: block;
                        text-align: right;
                        font-size: 11px;
                        color: white;
                        padding: 0 0 10px;
                    }
                    .fl-table thead, .fl-table tbody, .fl-table thead th {
                        display: block;
                    }
                    .fl-table thead th:last-child{
                        border-bottom: none;
                    }
                    .fl-table thead {
                        float: left;
                    }
                    .fl-table tbody {
                        width: auto;
                        position: relative;
                        overflow-x: auto;
                    }
                    .fl-table td, .fl-table th {
                        padding: 20px .625em .625em .625em;
                        height: 60px;
                        vertical-align: middle;
                        box-sizing: border-box;
                        overflow-x: hidden;
                        overflow-y: auto;
                        width: 120px;
                        font-size: 13px;
                        text-overflow: ellipsis;
                    }
                    .fl-table thead th {
                        text-align: left;
                        border-bottom: 1px solid #f7f7f9;
                    }
                    .fl-table tbody tr {
                        display: table-cell;
                    }
                    .fl-table tbody tr:nth-child(odd) {
                        background: none;
                    }
                    .fl-table tr:nth-child(even) {
                        background: transparent;
                    }
                    .fl-table tr td:nth-child(odd) {
                        background: #F8F8F8;
                        border-right: 1px solid #E6E4E4;
                    }
                    .fl-table tr td:nth-child(even) {
                        border-right: 1px solid #E6E4E4;
                    }
                    .fl-table tbody td {
                        display: block;
                        text-align: center;
                    }
                }

                  a{
                    padding: 5px 10px;
                  }
                  
                  .addUser {
                    background-color: #434169;
                    border: none;
                    color: white;
                    padding: 5px 10px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 14px;
                    margin: 2px;
                    cursor: pointer;
                    float: right;
                  }
                  
                  .search {
                    margin: 20px;
                  }  
                  
                  .highlighted-row {
                    background-color: yellow;
                  }


            `}</style>

            </div>

        );
    }
    if (!user) {
        return (
            <div>
                Please <Link to='/'>LOGIN</Link> to view forms
            </div>
        );
    }

    return (
        <div>
            <h5>You must be from the IT or PC department to view this page</h5>
        </div>
    );


}

export default ViewTp;

