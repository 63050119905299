const budgetCodes = {
    'Select Department': ['select option'],
    'After Sales': [
        'select options',
        'After Sales: Per diem (3719)',
    ],

    'Business Development': [
        'select option',
        'BD:Business Travel and Logistics (4301)',
    ],

    'Call Center': [
        'select option',
        'Call Centre: Call and Operational Costs (3701)',
    ],

    Finance: [
        'select options',
        'Other Expense (5307)',
    ],

    IT: [
        'select options',
        'IT: Expenses (4207)',
    ],
    'Logistics': [
        'select options',
        'Logistics: Management (3508)',
    ],
    Marketing: [
        'select options',
        'Marketing:Events (3603)',
    ],
    'Operations': [
        'select options',
        'Office Fuel and Travel (4606)',
    ],

    'People and Culture': [
        'select options',
        'PC: Events (4403)',
    ],

    'Product Development': [
        'select options',
        'PD: General & Admin (4109)',
    ],

    'Power Solutions': [
        'select options',
        'Power Solution: Installation Expenses (3801)',
    ],

    'Sales and Field Support': [
        'select options',
        'Sales and Field Support: Per diem (3402)',
    ],

    'Sales Support': [
        'select options',
        'Agents: Agent Support (3202)',
    ],

    Shops: [
        'select options',
        'Shops: Shop Operating Expenses (3304)',
    ],
    Products: [
        'select options',
    ],
    'Corporate sales': [
        'select options',
    ]
};

const departments = Object.keys(budgetCodes);

export default budgetCodes;
export { departments };
