const budgetCodes = {
    'Select Department': ['select option'],
    'After Sales': [
        'select options',
        'After Sales: Logistics for Field Ops (3716)',
        'After Sales: Inventory Spare Parts Provision Expen (3715)',
        'After Sales: Servicing (3711)',
        'After Sales: Fuel for Field Officers (3718)',
        'After Sales: Per diem (3719)',
        'After Sales: Accommodation Costs (3721)',
        'After Sales (3700)',
        'After Sales: Admin (3713)',
        'After Sales: Training (3714)',
    ],

    'Business Development': [
        'select option',
        'BD:Fundraising Costs (4304)',
        'BD:Board Expenses (4305)',
        'BD:Business Travel and Logistics (4301)',
        'BD:Conference Fees (4302)',
        'Business Development (4300)',
    ],

    'Call Center': [
        'select option',
        'Call Centre: Call and Operational Costs (3701)',
        'Call Centre: SMS Fees (3702)',
    ],

    'Corporate Finance': [
        'select option',
        'Corporate: BA Commission (3905)',
        'Corporate: Staff & Consultant (3906)',
        'Corporate: Contractor (BMC) (3907)',
        'Corporate: Operating Expenses (3902)',
        'Corporate: Sales Promotion, Launch & Goodwill (3908)',
        'Corporate: Travel Expenses (3903)',
        'Corporate Team Building & Entertainment (3909)',
        'Corporate: Field Expenses & Traveling (3910)',
    ],

    Finance: [
        'select option',
        'Other Expense (5307)',
        'Dues and Subscriptions (4803)',
        'Internal Audit and Risk Personnel (4806)',
        'Professional Fees (4802)',
        'Audit fees (4807)',
        'Legal Consultant (4809)',
        'Write Offs: Increase in Provision for Bad Debts (3102)',
        'Write Offs: Personnel - Repossession Agents (3103)',
        '4810 - Professional Fees - Others',
        'Tax Consultant (4808)',
        'Tax Liability Expenses (5304)',

    ],
    IT: [
        'select option',
        'After Sales: SMS Costs (3717)',
        'Call Centre: Software (3704)',
        'IT: Consultants (4202)',
        'IT: Expenses (4207)',
        'IT: Internet (4204)',
        'IT: SAAS (4201)',
        'Software License (4612)',
        'IT: License (4208)',
        'IT: PAYGO -Angaza System (4209)',
        'Agents (3200)',
        'IT: Business Travel (4205)',
        'IT: Conference (4206)',
    ],
    Marketing: [
        'select option',
        'Market Research (3608)',
        'Marketing:Branding and Creative (3601)',
        'Marketing:Content (3602)',
        'Marketing:Events (3603)',
        'Marketing:Paid Advertising (3604)',
        'Marketing:Point of Sales (3605)',
        'Marketing:Specials (3607)',
        'Marketing: CSR & Sponsorships -Corporate Social Responsibility (3610)',
        'Marketing: Free on Board (FOB) (3609)',
    ],

    'Logistics': [
        'select option',
        'Logistics: Vehicle Management Expenses (3502)',
        'Logistics: Fuel Expenses (3501)',
        'Logistics: Insurance (3505)',
        'Logistics: Management (3508)',
        'Logistics: Offloading and Bundling (3506)',
        'Logistics: Vehicle Hiring (3504)',
        'Logistics: Training (3509)',
        'Logistics: Distribution Costs (3510)',
        'Logistics: Vehicle Maintenance (3503)',
        'Logistics: Warehouse Rent (3511)',
    ],

    'Operations': [
        'select option',
        'Office Cleaning Supplies (4603)',
        'Office Communication (4609)',
        'Office Food Supplies (4602)',
        'Office Fuel and Travel (4606)',
        'Office Maintenance and Repairs (4601)',
        'Office Rent (4610)',
        'Operations Insurance (4608)',
        'Utilities and Bills (4604)',
        'Operations Training (4614)',
        'Operations Vehicle Management (4607)',
        'Printing & Stationery (4605)',
        'Permit and Registration (6807)',
    ],

    'People & Culture': [
        'select option',
        'Administration Personnel (4804)',
        'After Sales: Personnel (3712)',
        'BD:Personnel (4303)',
        'Call Centre: Personnel (3703)',
        'Corporate: Personnel (3901)',
        'Corporate: Training Expenses (3904)',
        'End of Service Benefit (SOPL) (7500)',
        'Finance Personnel (4805)',
        'General Management Personnel (4501)',
        'IT: Personnel (4203)',
        'Leadership Support (4502)',
        'Logistics: Personnel (3507)',
        'Marketing:Personnel (3606)',
        'Operations and Procurement: Personnel (4611)',
        'PD: Personnel (4106)',
        'Power Solution: Personnel (3803)',
        'Sales and Field Support: Personnel (3407)',
        'Sales Support: Personnel (3408)',
        'Shops: Shop Personnel (3307)',
        'Shops: Shop Staff Training (3303)',
        'TM: Administration (4404)',
        'TM: Events (4403)',
        'TM: Medical Insurance (4407)',
        'TM: Personnel (4406)',
        'TM: Professional Development (4405)',
        'TM: Recruitment and Onboarding (4401)',
        'TM: Wellness and Staff Support (4402)',
        'PC: Management Technical Services Agreement (MTSA) (4409)',
        'Employer NASSIT (7501)',
        'Staff Housing Allowance (7111)',
        'TM: Staff Year End Bonus (4408)',
        'PD: General & Admin (4109)',
        'PD: Rollout (4104)',
        'PD: Commission on new product sales (4108)',
        'PD: Pilot (4103)',
        'PD: Post Launch (4105)',
        'PD: Research and Planning (4101)',
        'PD: Testing and Development (4102)',
        'PD: Exploratory Products (4107)',
    ],

    'Power Solution': [
        'Power Solution: Additional Equipment (3802)',
        'Power Solution: Installation Expenses (3801)',
        'Power Solution: Other Operational Expenses (3805)',
    ],
    'Sales and Field Support': [
        'select option',
        'Sales and Field Support: Accommodation Costs (3401)',
        'Sales and Field Support: Commissions and Bonuses (3404)',
        'Sales and Field Support: Per diem (3402)',
        'Sales and Field Support: Team Housing (3403)',
        'Sales and Field Support: Travel Expenses (3405)',
        'Sales and Field Support: Vehicle Maintenance Costs (3406)',
    ],
    'Sales Support': [
        'select option',
        'Agent: Agent Commission (3205)',
        'Agents: Agent Awards (3204)',
        'Agents: Agent Training (3203)',
        'Agents: Agent Management Personnel (3206)',
        'Agents: Agent Set Up Costs (3201)',
        'Agents: Agent Support (3202)',
    ],
    'Shops': [
        'select option',
        'Shops: Shop Rent and Registrations (3305)',
        'Shops: Brand Ambassador Commission and Expenses (3301)',
        'Shops: PDS Brand Ambassador Commission (3308)',
        'Shops: Shop Operating Expenses (3304)',
        'Shops: Shop Repairs and Maintenance (3306)',
        'Security Personnel (4613)',
        'Shops: Shop Opening Costs (3302)',
    ],
    Products: [
        'select option',
    ]
};

const departments = Object.keys(budgetCodes);

export default budgetCodes;
export { departments };
