/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable no-console */
import React from 'react';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import '../styles/pettycash.css';
// import { BsFillPlusCircleFill, BsCloudCheckFill } from 'react-icons/bs';
// import { MdCloudUpload } from 'react-icons/md';
// import {
//     TextField,
//     Radio,
//     RadioGroup,
//     FormControlLabel,
//     FormControl,
//     InputLabel,
//     OutlinedInput,
//     InputAdornment,
//     FormLabel,
//     Button,
//     CircularProgress,
//     Select,
//     MenuItem,
// } from '@mui/material';
import Greeting from '../components/greeting';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
// import Item from '../components/Item';
// import { departments } from '../components/budgetCodes';

function ViewPc() {
    const [users, setUsers] = React.useState([]);
    // const [searchQuery, setSearchQuery] = React.useState('');
    // const [retiement, setRetiement] = React.useState(0);
    // const [reqid, setReqid] = React.useState(0);
    // const [retiementitems, setRetiementitems] = React.useState(0);

    let user = null;
    let userid = null;
    if (JSON.parse(localStorage.getItem('userdata')) !== null) {
        const { 'First Name': username, '_id': id } = JSON.parse(
            localStorage.getItem('userdata')
        );
        user = username;
        userid = id;

    }
    // console.log('UserID',userid);

    const API_URI = `https://digital-forms-backend.onrender.com/requests/pettycash/${userid}`;
    const API_URI2 = `https://digital-forms-backend.onrender.com/viewret`;
    const headers = { 'content-type': 'application/json' };

    // console.log(retiement)

    React.useEffect(() => {
        axios.get(API_URI, { headers }).then((response) => {
            const data = response.data
            // console.log(data);
            // get the approvalStatus from the data under the details object
            // fillter the data.details.approvalStatus and return the data
            // that has the approvalStatus of Approved
            // const Approved = data.filter((item) => item.details.approvalStatus === 'approved');
            // console.log(Approved)
            // console.log(data[0].details)
            // console.log(data);
            // get the _id from the data
            // const id = data.map((item) => item._id);
            // console.log(id);
            // setReqid(id);

            setUsers(data);



        });
    }, []);
    // console.log(reqid)

    // get the data from the viewret
    React.useEffect(() => {
        axios.get(API_URI2, { headers }).then((response) => {
            const data = response.data
            console.log(data);
            // get where requestid is in the reqid array and return the data
            const retirement = data.filter((item) => item.requestid);
            console.log(retirement);
            // setRetiement(retirement);

            // get the requestid from the data
            const id = data.map((item) => item.requestid);
            // console.log(id);
            // create a new array and store the requestid in it
            const reqid = [];
            for (const i in id) {
                // reqid.push(id[i]);
                // push the data into the array
                reqid.push(data[i]);
            }
            console.log(reqid);

            // get the items from reqid
            const items = users.map((user) => Object.values(user.details.items));
            // setRetiementitems(items);
            console.log(items);


        });
    }, []);


    // take the retirement array and check if the requestid is in the reqid array and return the data




    // console.log(users);
    // get the items from the users array
    const id = users.map((user) => user._id);
    const currency = users.map((user) => user.details['currency']);
    console.log(currency);

    const items = users.map((user) => Object.values(user.details.items));
    // console.log(items);

    const joined = items.map((item, index) => {
        const { retirement, balance, currency } = users[index].details;

        return { id: id[index], items: item, retirement, balance, currency };
    });



    // console.log(joined);
    // console.log(retiementitems);
    // get the index of items in joined in other words I want you to check 
    // how many objects are in the items array and then start counting from there
    const index = joined.map((item) => item.items.length);
    // insted of you setting the total start counting from 0 and store it
    // in a variable and then add the total to it
    const total = joined.map((item) => {
        let count = 0;
        for (const i in item.items) {
            // start to count from 0 to the end of the item.items
            item.items[i].index = count;
            count += 1;
        }
        return count;
    });
    console.log(total);
    console.log(index);
    // console.log(items)
    if (user) {
        return (
            <div>
                <Greeting
                    user={user}
                    text='Please click on the plus icon to add your Retirements'
                />
                <div className="table-wrapper">
                    <table className="fl-table">
                        <thead>
                            <tr>
                                <th>Request Number</th>
                                <th>Description</th>
                                <th>Total Items</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {joined.reduce((groupedItems, item) => {
                                item.items.forEach((value) => {
                                    const existingGroup = groupedItems.find((group) => group.id === item.id);
                                    if (existingGroup) {
                                        existingGroup.items.push(value);
                                    } else {
                                        groupedItems.push({
                                            id: item.id,
                                            items: [value],
                                            currency: item.currency,
                                        });
                                    }
                                });
                                return groupedItems;
                            }, []).map((group, index) => (
                                <tr key={group.id}>
                                    <td>{index + 1}</td>
                                    <td colSpan="1">
                                        {group.items.slice(0, 3).map((value, index) => {
                                            const splitIndex = value.description.indexOf(' ', value.description.indexOf(' ', value.description.indexOf(' ') + 1) + 1);
                                            const truncatedDescription = splitIndex !== -1 ? value.description.substring(0, splitIndex) + '...' : value.description;
                                            return (
                                                <React.Fragment key={value.name}>
                                                    {index > 0 && ', '}
                                                    {value.budgetcode} {value.name} {truncatedDescription}
                                                </React.Fragment>
                                            );
                                        })}
                                        {group.items.length > 3 && '...'}
                                    </td>
                                    <td>{group.items.length}</td>
                                    <td>
                                        <Link to={`/addret/${group.id}?${group.items.map((value) => value.name).join('-')}`}>
                                            {/* <a href={`/addret/${group.id}?${group.items.map((value) => value.name).join('-')}`} className="btn border-shadow update"> */}
                                            <span className="text-gradient"><FontAwesomeIcon icon={faPlus} /></span>
                                            {/* </a> */}
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <style >{`
                    
                    *{
                        box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                    }
                    
                    /* Table Styles */
                    
                    .table-wrapper{
                        margin: 10px 70px 70px;
                        box-shadow: 0px 35px 50px rgba( 0, 0, 0, 0.2 );
                    }
                    
                    .fl-table {
                        border-radius: 5px;
                        font-size: 12px;
                        font-weight: normal;
                        border: none;
                        border-collapse: collapse;
                        width: 100%;
                        max-width: 100%;
                        white-space: nowrap;
                        background-color: white;
                    }
                    
                    .fl-table td, .fl-table th {
                        text-align: center;
                        padding: 8px;
                    }
                    
                    .fl-table td {
                        border-right: 1px solid #f8f8f8;
                        font-size: 12px;
                    }
                    
                    .fl-table thead th {
                        color: #ffffff;
                        background: #dcc57d;
                    }
                    
                    
                    .fl-table thead th:nth-child(odd) {
                        color: #ffffff;
                        background: #434169;
                    }
                    
                    .fl-table tr:nth-child(even) {
                        background: #F8F8F8;
                    }
                    
                    /* Responsive */
                    
                    @media (max-width: 767px) {
                        .fl-table {
                            display: block;
                            width: 100%;
                        }
                        .table-wrapper:before{
                            content: "Scroll horizontally >";
                            display: block;
                            text-align: right;
                            font-size: 11px;
                            color: white;
                            padding: 0 0 10px;
                        }
                        .fl-table thead, .fl-table tbody, .fl-table thead th {
                            display: block;
                        }
                        .fl-table thead th:last-child{
                            border-bottom: none;
                        }
                        .fl-table thead {
                            float: left;
                        }
                        .fl-table tbody {
                            width: auto;
                            position: relative;
                            overflow-x: auto;
                        }
                        .fl-table td, .fl-table th {
                            padding: 20px .625em .625em .625em;
                            height: 60px;
                            vertical-align: middle;
                            box-sizing: border-box;
                            overflow-x: hidden;
                            overflow-y: auto;
                            width: 120px;
                            font-size: 13px;
                            text-overflow: ellipsis;
                        }
                        .fl-table thead th {
                            text-align: left;
                            border-bottom: 1px solid #f7f7f9;
                        }
                        .fl-table tbody tr {
                            display: table-cell;
                        }
                        .fl-table tbody tr:nth-child(odd) {
                            background: none;
                        }
                        .fl-table tr:nth-child(even) {
                            background: transparent;
                        }
                        .fl-table tr td:nth-child(odd) {
                            background: #F8F8F8;
                            border-right: 1px solid #E6E4E4;
                        }
                        .fl-table tr td:nth-child(even) {
                            border-right: 1px solid #E6E4E4;
                        }
                        .fl-table tbody td {
                            display: block;
                            text-align: center;
                        }
                    }
                    
                  
                  

                  a{
                    padding: 5px 10px;
                  }
                  
                  .addUser {
                    background-color: #022f8e;
                    border: none;
                    color: white;
                    padding: 5px 10px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 14px;
                    margin: 2px;
                    cursor: pointer;
                    float: right;
                  }
                  
                  .search {
                    margin: 20px;
                  }                  


            `}</style>
            </div>

        );
    }
}

export default ViewPc;
