/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable no-console */
import React from 'react';
import axios from 'axios';
import Greeting from '../components/greeting';
import { Link, useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    CircularProgress,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import InputAdornment from '@mui/material/InputAdornment';

function AddTp() {
    const [formData, setFormData] = React.useState({});
    const data = JSON.parse(localStorage.getItem('userdata'));
    const [loading, setLoading] = React.useState(false);
    const currencyLabel = window.location.href.includes('lib') ? 'LRD' : 'LE';

    //   const API_URI = 'https://digital-forms-backend.onrender.com/addtp';
    const API_URI = 'https://digital-forms-backend.onrender.com/addtp';
    const headers = { 'content-type': 'application/json' };
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(API_URI, { ...formData, user: data }, { headers });
            setLoading(false);
            window.confirm('You have successfully added the Tranportation Data.');
            navigate('/viewtp');
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };



    return (
        <div className="formPage">
            <Greeting
                user=' Manager '
                text='Please add a new Transportation Amount'
            />
            <form onSubmit={handleSubmit} className="form" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>

                <div className="row">
                    <TextField
                        label='From Location'
                        name='FromLocation'
                        required
                        InputProps={{
                            readOnly: false,
                        }}
                        variant='outlined'
                        onChange={handleChange}
                    />
                </div>
                <div className="row">
                    <TextField
                        label='To Location'
                        name='ToLocation'
                        required
                        InputProps={{
                            readOnly: false,
                        }}
                        variant='outlined'
                        onChange={handleChange}
                    />
                </div>

                <div className="row">
                    <TextField
                        type="number"
                        label='Oneway Amount'
                        name='oneamount'
                        required
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{currencyLabel}</InputAdornment>,
                        }}
                        variant='outlined'
                        onChange={handleChange}
                    /></div>




                <Button
                    color='primary'
                    variant='outlined'
                    type='submit'
                    size='large'
                    className='submitButton'
                >
                    {loading ? <CircularProgress color='inherit' /> : 'Add Transportation'}
                </Button>
                <div className='row'>
                    <Link to='/viewtp'>
                        <FontAwesomeIcon icon={faArrowLeft} /> Back to View Transportat
                    </Link>
                </div>
            </form>
            <style >{`
        .form {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0;
          margin-bottom: 20px;
        }

        .column {
          display: flex;
          flex-direction: row;
          
          justify-content: space-between;
          align-items: center;
        }

        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 10px 10px;
        }

        button {
          margin: 20px 0;
          padding: 10px;
          background-color: #022f8e;
          color: #fff;
          border: none;
          border-radius: 5;
          float: right;
        }
      `}</style>
        </div>
    );
}

export default AddTp;
