import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Greeting from '../components/greeting';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


function ViewUsers() {
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);

    const handleFullNameClick = (userId) => {
        setSelectedRow(userId);
    };

    let user = null;
    let department = null;
    if (JSON.parse(localStorage.getItem('userdata')) !== null) {
        const { 'First Name': username, Department } = JSON.parse(
            localStorage.getItem('userdata')
        );
        user = username;
        department = Department;
    }
    // check the url if it has sl in it
    // if it does, then filter the users by Base Country and display them
    // if it doesn't, then display all the users
    const url = window.location.href;
    const sl = url.includes('sl') || url.includes('3000');
    const lib = url.includes('lib');

    useEffect(() => {
        axios.get('https://digital-forms-backend.onrender.com/allusers')

            .then(response => {
                // filter the users by Base Country
                if (sl) {
                    const filteredUsers = response.data.filter(user => user['Base Country'] === 'Sierra Leone');
                    setUsers(filteredUsers);
                } else if (lib) {
                    const filteredUsers = response.data.filter(user => user['Base Country'] === 'Liberia');
                    setUsers(filteredUsers);
                }
                // console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const navigate = useNavigate();
    const handleDeleteUser = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this employee?');
        if (confirmDelete) {
            await axios.delete(`https://digital-forms-backend.onrender.com/deleteuser/${id}`);
            window.confirm('You have successfully deleted the user.');
            // reload the page
            window.location.reload();
            navigate('/viewusers');

        } else {
            return;
        }



    };


    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredUsers = users.filter(user => {
        const fullName = user['Full Name'] ? user['Full Name'].toLowerCase() : '';
        const jobTitle = user['Job Title'] ? user['Job Title'].toLowerCase() : '';
        const department = user['Department'] ? user['Department'].toLowerCase() : '';
        const baseCountry = user['Base Country'] ? user['Base Country'].toLowerCase() : '';
        const lineManagerName = user['Line Manager Name'] ? user['Line Manager Name'].toLowerCase() : '';

        const lineManagerEmail = user['Line Manager Email Address'] ? user['Line Manager Email Address'].toLowerCase() : '';
        const employeeEmail = user['Employee Email Address'] ? user['Employee Email Address'].toLowerCase() : '';
        const staffId = user['StaffID'] ? user['StaffID'].toLowerCase() : '';
        const phoneNo = user['PhoneNo'] ? user['PhoneNo'].toLowerCase() : '';
        const accommodation = user['Accommodation'] ? user['Accommodation'].toLowerCase() : '';
        const meals = user['Meals'] ? user['Meals'].toLowerCase() : '';
        const internationalaccommodation = user['InternationalAccommodation'] ? user['InternationalAccommodation'].toLowerCase() : '';
        const internationalmeals = user['InternationalMeals'] ? user['InternationalMeals'].toLowerCase() : '';
        const searchQueryLower = searchQuery.toLowerCase();


        return (
            fullName.includes(searchQueryLower) ||
            jobTitle.includes(searchQueryLower) ||
            department.includes(searchQueryLower) ||
            baseCountry.includes(searchQueryLower) ||
            lineManagerName.includes(searchQueryLower) ||
            lineManagerEmail.includes(searchQueryLower) ||
            employeeEmail.includes(searchQueryLower) ||
            staffId.includes(searchQueryLower) ||
            phoneNo.includes(searchQueryLower) ||
            accommodation.includes(searchQueryLower) ||
            meals.includes(searchQueryLower) ||
            internationalaccommodation.includes(searchQueryLower) ||
            internationalmeals.includes(searchQueryLower)
        );
    });

    if (user && department === "IT" || department === "People & Culture" || department === "Senior Management") {

        return (
            <div>
                <Greeting
                    user='Manager'
                    text='These are the Users that are in the database'
                />

                <div className='search'>
                    <input type="text" placeholder="Search" value={searchQuery} onChange={handleSearch} />

                    <Link to='/addusers'>
                        <button className='addUser'>Add User</button>
                    </Link>
                </div>


                <div className="table-wrapper">
                    <table className="fl-table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Full Name</th>
                                <th>Job Title</th>
                                <th>Department</th>
                                <th>Base Country</th>
                                <th>Line Manager Name</th>
                                <th>Line Manager Email Address</th>
                                <th>Budget Holder</th>
                                <th>Budget Holder Email</th>
                                <th>Employee Email Address</th>
                                <th>Staff ID</th>
                                <th>Phone Number</th>
                                <th>Accommodation</th>
                                <th>Meals</th>
                                <th>International Accommodation ($)</th>
                                <th>International Meals ($)</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.map(user => (
                                <tr key={user._id} className={selectedRow === user._id ? 'highlighted-row' : ''}>
                                    {/* start counting from 1  */}
                                    <td onClick={() => handleFullNameClick(user._id)}>{filteredUsers.indexOf(user) + 1}</td>
                                    <td onClick={() => handleFullNameClick(user._id)}>{user['Full Name']}</td>
                                    <td>{user['Job Title']}</td>
                                    <td>{user['Department']}</td>
                                    <td>{user['Base Country']}</td>
                                    <td>{user['Line Manager Name']}</td>
                                    <td>{user['Line Manager Email Address']}</td>
                                    <td>{user['Budget Holder']}</td>
                                    <td>{user['Budget Holder Email']}</td>
                                    <td>{user['Employee Email Address']}</td>
                                    <td>{user['StaffID']}</td>
                                    <td>{user['PhoneNo']}</td>
                                    <td>{user['Accommodation']}</td>
                                    <td>{user['Meals']}</td>
                                    <td>{user['InternationalAccommodation']}</td>
                                    <td>{user['InternationalMeals']}</td>
                                    <td>
                                        <Link to={`/edituser/${user._id}`}>
                                            <a href="/edituser" className="btn border-shadow update">
                                                <span className="text-gradient"><FontAwesomeIcon icon={faPencilAlt} /></span>
                                            </a>
                                        </Link>
                                        <button className="btn border-shadow delete" data-id={user._id} onClick={() => handleDeleteUser(user._id)}>
                                            <span className="text-gradient"><FontAwesomeIcon icon={faTimes} /></span>
                                        </button>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <style >{`
                *{
                    box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                }
                
        
                
                /* Table Styles */
                
                .table-wrapper{
                    margin: 11px 20px 70px;
                    box-shadow: 0px 35px 50px rgba( 0, 0, 0, 0.2 );
                    overflow-x: auto;
                    
                }
                
                .fl-table {
                    border-radius: 5px;
                    font-size: 12px;
                    font-weight: normal;
                    border: none;
                    border-collapse: collapse;
                    width: 100%;
                    max-width: 100%;
                    white-space: nowrap;
                    background-color: white;
                    overflow-x: auto;
                }
                
                .fl-table td, .fl-table th {
                    text-align: center;
                    padding: 8px;
                }
                
                .fl-table td {
                    border-right: 1px solid #f8f8f8;
                    font-size: 12px;
                }
                
                .fl-table thead th {
                    color: #ffffff;
                    background: #dcc57d;
                }
                
                
                .fl-table thead th:nth-child(odd) {
                    color: #ffffff;
                    background: #434169;
                }
                
                /* add a fix horizontal scroll */
                .fl-table tbody {
                    overflow-y: auto;
                    overflow-x: hidden;
                }
                
                
                /* Responsive */
                
                @media (max-width: 767px) {
                    .fl-table {
                        display: block;
                        width: 100%;
                    }
                    .table-wrapper:before{
                        content: "Scroll horizontally >";
                        display: block;
                        text-align: right;
                        font-size: 11px;
                        color: white;
                        padding: 0 0 10px;
                    }
                    .fl-table thead, .fl-table tbody, .fl-table thead th {
                        display: block;
                    }
                    .fl-table thead th:last-child{
                        border-bottom: none;
                    }
                    .fl-table thead {
                        float: left;
                    }
                    .fl-table tbody {
                        width: auto;
                        position: relative;
                        overflow-x: auto;
                    }
                    .fl-table td, .fl-table th {
                        padding: 20px .625em .625em .625em;
                        height: 60px;
                        vertical-align: middle;
                        box-sizing: border-box;
                        overflow-x: hidden;
                        overflow-y: auto;
                        width: 120px;
                        font-size: 13px;
                        text-overflow: ellipsis;
                    }
                    .fl-table thead th {
                        text-align: left;
                        border-bottom: 1px solid #f7f7f9;
                    }
                    .fl-table tbody tr {
                        display: table-cell;
                    }
                    .fl-table tbody tr:nth-child(odd) {
                        background: none;
                    }
                    .fl-table tr:nth-child(even) {
                        background: transparent;
                    }
                    .fl-table tr td:nth-child(odd) {
                        background: #F8F8F8;
                        border-right: 1px solid #E6E4E4;
                    }
                    .fl-table tr td:nth-child(even) {
                        border-right: 1px solid #E6E4E4;
                    }
                    .fl-table tbody td {
                        display: block;
                        text-align: center;
                    }
                }

                  a{
                    padding: 5px 10px;
                  }
                  
                  .addUser {
                    background-color: #434169;
                    border: none;
                    color: white;
                    padding: 5px 10px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 14px;
                    margin: 2px;
                    cursor: pointer;
                    float: right;
                  }
                  
                  .search {
                    margin: 20px;
                  }  
                  
                  .highlighted-row {
                    background-color: yellow;
                  }


            `}</style>

            </div>

        );
    }
    if (!user) {
        return (
            <div>
                Please <Link to='/'>LOGIN</Link> to view forms
            </div>
        );
    }

    return (
        <div>
            <h5>You must be from the IT or PC department to view this page</h5>
        </div>
    );


}

export default ViewUsers;

