/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable no-console */
import React from 'react';
import { FormControl, FormLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import budgetCodes, { departments } from './budgetCodes';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { set } from 'date-fns';


function Item({
  // eslint-disable-next-line react/prop-types
  count, data, setData, itemsTotal, sDepartment, currency
}) {

  const [unitCost, setCost] = React.useState(1);
  const [amount, setAmount] = React.useState(1);
  const [itemName, setItemName] = React.useState('');
  const [total, setTotal] = React.useState(0);
  const [description, setInfo] = React.useState('');
  const [budgetCode, setBudgetCode] = React.useState([]);
  const [department] = React.useState('select option');
  const [balance, setBalance] = React.useState(0);
  const [itemsArray, setItemsArray] = React.useState([]);
  const [details, setDetails] = React.useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);


  // const data = JSON.parse(localStorage.getItem('userdata'));
  let user = null;
  let userid = null;
  if (JSON.parse(localStorage.getItem('userdata')) !== null) {
    const { 'First Name': username, '_id': id } = JSON.parse(
      localStorage.getItem('userdata')
    );
    user = username;
    userid = id;

  }

  // get the value of the url after edituser/
  const url = window.location.href;
  // get the id from the url starting from / and ending at ?
  const id = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('?'));
  // console.log(id);
  // get the budgetcode from the url starting from ? and ending at the end of the url
  const bcode = url.substring(url.lastIndexOf('?') + 1, url.lastIndexOf('-'));
  // remove the whitespace from the budgetcode
  bcode.replace(/%20/g, ' ');
  // console.log(bcode2);


  const itemindex = url.substring(url.lastIndexOf('-') + 1, url.length);
  // console.log(itemindex);

  // get the users in the database
  React.useEffect(() => {
    setLoading(true);
    axios.get(`https://digital-forms-backend.onrender.com/requests/pettycash/${userid}`)
      .then(response => {
        // console.log(response.data);
        const pcdata = response.data.filter((item) => item._id === id);
        // console.log(userid);
        const details = pcdata[0]['details'];
        setDetails(details);
        // console.log(details['items']);
        const items = details['items'];
        // set the itemsArray to the array of items

        const itemsArrays = [];// create an array to store the matching items
        // iterate over the items object and check each item's budgetcode property
        const itemsArrayss = [];
        for (const key in items) {
          // eslint-disable-next-line no-prototype-builtins
          if (items.hasOwnProperty(key)) {
            const item = items[key];
            itemsArrays.push(item); // add the matching item to the array
            if (item.name === bcode) {
              itemsArrayss.push(item); // add the matching item to the array
            }
          }
        }
        // console.log("Match:", itemsArrays);
        setItemsArray(itemsArrays);



        // get the item with the itemindex
        const item = itemsArrays.filter((item) => item.itemindex === itemindex);
        // console.log("Item:", item);
        setLoading(false);

      })
      .catch(error => {
        console.log(error);
      });
  }, [id]);
  // console.log(itemsArray);
  // console.log(details.finaltotal);
  console.log(details.currency);


  // const handleNameChange = (e) => {
  //   setItemName(e.target.value);
  // };

  React.useEffect(() => {
    setTotal(amount * unitCost);
    setData({
      ...data,
      [count]: {
        budgetcode: budgetCode,
        name: name,
        description: description,
        cost: unitCost,
        amount: amount,
        total: total,
        retiement: 0,
        balance: balance,
      },
    });
  }, [
    itemName,
    description,
    amount,
    unitCost,
    total,
    balance,
    count,
    data,
    setData,
    itemsTotal,
    sDepartment,
    currency,

  ]);


  const totalitems = itemsArray.length;
  const forms = [];
  for (let i = 0; i < totalitems;) {
    const { budgetcode, names, description, cost, amount, total, retiement, balance } = itemsArray[i];
    console.log(budgetcode);
    // I want to push the budgetcode to the budgetCode array for just the totalitems times
    for (let j = 0; j < totalitems; j++) {
      if (budgetcode in budgetCode) {
        console.log("Budgetcode already exists");
        console.log("NOTE", budgetCode)
      } else {
        budgetCode.push(budgetcode);
      }
    }
    // set the values of the fields to the values of the itemsArray items 
    // setBudgetCode(budgetcode);
    forms.push(
      <div className="elDiv">
        <FormControl sx={{ m: 1, minWidth: 200 }} size='small'>
          <FormLabel id='demo-simple-select-label'>
            Budget Code?
          </FormLabel>
          <TextField
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={budgetcode}
            name='budgetcode'
            required
          // onChange={setBudgetCode(itemsArray[i].budgetcode)}
          />
        </FormControl>

        <TextField
          className="textInput"
          required
          type="text"
          size="small"
          id="outlined-required"
          label="Item Name"
          autoComplete="off"
          value={names}
          name={`item${count}`}
        // onChange={setItemName(itemsArray[i].name)}

        />
        <TextField
          className="textInput"
          required
          type="text"
          size="small"
          id="outlined-required"
          label="description"
          multiline
          minRows={2}
          name={`item${count}`}
          // onChange={setInfo(itemsArray[i].description)}
          value={description}
        />
        <TextField
          className="textInput"
          required
          type="number"
          size="small"
          label="Unit Cost"
          // onChange={setCost(itemsArray[i].cost)}
          name={`cost${count}`}
          value={cost}
        />

        <TextField
          type="number"
          className="textInput"
          size="small"
          label="Quantity"
          value={amount}
          // onChange={setAmount(itemsArray[i].amount)}
          name={`amount${count}`}
        />
        <TextField
          className="textInput"
          type="number"
          size="small"
          label="Total"
          value={total}
        />
        <TextField
          className="textInput"
          type="number"
          size="small"
          label="Retiement"
          value={retiement}
        />
        {/* make the balance field value to be the value for total - retiement */}
        <TextField
          className="textInput"
          type="number"
          size="small"
          label="Balance"
          value={balance}
        />


      </div>,
    );
    i++;
  }

  console.log(budgetCode);


  return (
    <div>
      {forms}
    </div>

  );
}

export default Item;
