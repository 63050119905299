/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable no-console */
import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/perdiem.css';
import {
    TextField,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    OutlinedInput,
    Button,
    CircularProgress,
    InputAdornment,
    Checkbox,
    Select,
    MenuItem,
} from '@mui/material';

import Greeting from '../components/greeting';
import BasicDatePicker from '../components/BasicDatePicker';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import budgetCodes, { departments } from '../components/budgetCodespd';
import { set } from 'date-fns';

function PerDiem() {
    const [formData, setFormData] = React.useState({});
    // const [dateValue, setDateValue] = React.useState(new Date());
    const data = JSON.parse(localStorage.getItem('userdata'));
    const [claim, setClaim] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [reverse, setReverse] = React.useState();
    const [budgetCode, setBudgetCode] = React.useState(['select option']);
    const [department, setDepartment] = React.useState('Select Department');

    const [currencyLabel, setCurrencyLabel] = React.useState(
        window.location.href.includes('lib') ? 'LRD' : 'LE'
    );
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [days, setDays] = React.useState(0);
    const [nights, setNights] = React.useState(0);
    const [value, setValue] = React.useState(false);
    const minDate = !value ? new Date() : undefined;
    const [isValid, setIsValid] = React.useState(true);
    const [errorpurpose, setErrorPurpose] = React.useState(' ');
    const [setpurpose, setPurpose] = React.useState(' ');
    const [location, setLocation] = React.useState('');
    const [tpAmount, setTpAmount] = React.useState('');
    const [destination, setDestination] = React.useState('');
    const [addTpAmount, setAddTpAmount] = React.useState('');
    const [showDestination, setShowDestination] = React.useState(false);
    const [transportData, setTransportData] = React.useState([]);
    const [fromLocation, setFromLocation] = React.useState('');
    const [toLocation, setToLocation] = React.useState('');

    React.useEffect(() => {
        axios.get('https://digital-forms-backend.onrender.com/alltp')
            .then(response => {
                setTransportData(response.data);
            })
            .catch(error => console.error('Error fetching transportation data:', error));
    }, []);

    const getUniqueLocations = () => {
        const fromLocations = transportData.map(tp => tp.FromLocation);
        const toLocations = transportData.map(tp => tp.ToLocation);
        return Array.from(new Set([...fromLocations, ...toLocations]));
    };

    const uniqueLocations = getUniqueLocations();
    const uniqueToLocations = fromLocation ? transportData.filter(tp => tp.FromLocation === fromLocation || tp.ToLocation === fromLocation).map(tp => tp.FromLocation === fromLocation ? tp.ToLocation : tp.FromLocation) : [];

    const handleFromLocationChange = (e) => {
        const selectedValue = e.target.value;
        setFromLocation(selectedValue);

        if (selectedValue === "Other") {
            setShowDestination(true);
            setToLocation(''); // Reset toLocation and tpAmount if "Other" is selected
            setTpAmount(0);
        } else {
            setShowDestination(false);
            // Existing logic to handle fromLocation change
            setToLocation('');
            setTpAmount(0);
        }
    };

    const handleToLocationChange = (e) => {
        setToLocation(e.target.value);
        const selectedTp = transportData.find(tp => (tp.FromLocation === fromLocation && tp.ToLocation === e.target.value) || (tp.ToLocation === fromLocation && tp.FromLocation === e.target.value));
        setTpAmount(selectedTp ? selectedTp.oneamount * 2 : 0);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setEndDate(null);
        setNights(0);
        setDays(0);
        // console.log("MinDate ", minDate)
        // console.log(date)
        // console.log(value)
        minDate.setHours(0, 0, 0, 0);
        // console.log("MinDate ", minDate)
        // console.log("date ", date)
        if (!value) {
            const isValidDate = date >= minDate;
            setIsValid(isValidDate);
            // console.log("isValidDate ", isValidDate)
        }
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        if (startDate && date) {
            const diffTime = Math.abs(date - startDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

            // Check if diffDays is 0 and set setNights accordingly
            const setNightValue = diffDays === 0 ? 0 : diffDays - 1;

            setNights(setNightValue);
            setDays(diffDays);
            if (diffDays > 1) {
                setFormData({ ...formData, accommodation: 'Yes' });
            }
        }
    };

    const handleReverse = React.useCallback((e) => {
        setReverse(e.target.value);
        setValue(e.target.value === 'true');
    });

    const calculateClaim = React.useCallback(() => {
        // console.log(days, nights);
        let totalClaim = 0;
        // check if checked is true
        if (
            checked &&
            data.InternationalMeals &&
            data.InternationalAccommodation
        ) {
            const mealVal =
                parseInt(data.InternationalMeals.replace(/[^a-z0-9]/gi, '')) *
                days;
            const accVal =
                parseInt(
                    data.InternationalAccommodation.replace(/[^a-z0-9]/gi, '')
                ) * nights;
            totalClaim = nights > 0 ? mealVal + accVal : mealVal;
        } else {
            const mealVall =
                parseInt(data.Meals.replace(/[^a-z0-9]/gi, '')) * days;
            const accVall =
                parseInt(data.Accommodation.replace(/[^a-z0-9]/gi, '')) *
                nights;
            totalClaim = nights > 0 ? mealVall + accVall : mealVall;

        }

        const tpAmountNumber = parseInt(tpAmount) || 0;
        const addTpAmountNumber = parseInt(addTpAmount) || 0;
        totalClaim += tpAmountNumber + (addTpAmountNumber * 2)

        setClaim(totalClaim);
        setFormData({ ...formData, TOTALCLAIM: totalClaim });
    }, [data.Meals, data.Accommodation, formData, tpAmount]);


    const handleChange = (e) => {
        e.preventDefault();
        setIsValid(true);
        const { name, value } = e.target;
        if (name === 'fromLocation') {
            setFromLocation(value);
            // Update the form data directly here
            setFormData({ ...formData, FromLocation: value });
        } else if (name === 'toLocation') {
            setToLocation(value);
            setFormData({ ...formData, ToLocation: value });
            // Compute and set the transport amount based on selected locations
            const selectedTp = transportData.find(tp => tp.FromLocation === fromLocation && tp.ToLocation === value);
            const newTpAmount = selectedTp ? selectedTp.oneamount * 2 : 0;
            setTpAmount(newTpAmount);
            setFormData({ ...formData, tpAmount: newTpAmount });
        } else if (name === 'addTpAmount') {
            setTpAmount(value * 2)
        }
        else {
            // Handle other form changes
            setFormData({ ...formData, [name]: value.trim() });
        }

        // check if checked
        if (checked) {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value.trim(),
                // date: dateValue.toDateString() || '',
                days: days,
                nights: nights,
                currency: currencyLabel,
                approvalStatus: 'pending',
                approvalStatusbh: 'pending',
                approvalStatusf: 'pending',
                approvalStatuspc: 'pending',
                approvalStatuscm: 'pending',
                createdAt: new Date().toLocaleString(),
                arrival_date: startDate.toDateString(),
                departure_date: endDate.toDateString(),
                place: 'International',
                department: department,
                budgetCode: budgetCode,
                FromLocation: fromLocation,
                ToLocation: toLocation,
                tpAmount: tpAmount,
            });
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value.trim(),
                // date: dateValue.toDateString() || '',
                days: days,
                nights: nights,
                currency: currencyLabel,
                approvalStatus: 'pending',
                approvalStatusbh: 'pending',
                approvalStatusf: 'pending',
                approvalStatuspc: 'pending',
                approvalStatuscm: 'pending',
                createdAt: new Date().toLocaleString(),
                arrival_date: startDate.toDateString(),
                departure_date: endDate.toDateString(),
                place: 'Local',
                department: department,
                budgetCode: budgetCode,
                FromLocation: fromLocation,
                ToLocation: toLocation,
                tpAmount: tpAmount,
            });
        }
    };

    // console.log(tpAmount);
    // axios.interceptors.request.use(function (config) {
    //     // spinning start to show
    //     setLoading(true)
    //     return config
    // })

    // axios.interceptors.response.use(function (response) {
    //     // spinning hide
    //     setLoading(false)
    //     return response
    // })

    const API_URI = 'https://digital-forms-backend.onrender.com/requests/perdiem';
    // const API_URI = 'http://localhost:3002/requests/perdiem';

    const headers = { 'content-type': 'application/json' };

    const navigate = useNavigate();
    const [isFormValid, setIsFormValid] = React.useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (setpurpose === ' ') {
            setIsValid(false);
            setErrorPurpose('Please select a purpose');

            return;
        }
        setErrorPurpose(' ');
        setLoading(true);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });

        console.log('---sending data --')
        console.log('formData: ', formData)
        console.log('user: ', data)
        console.log('header: ', headers)

        await axios
            .post(API_URI, { ...formData, user: data }, headers)
            .then((response) => {
                setLoading(false);
                window.confirm(
                    'Your Request was successful. You Line Manager will receive the details'
                );
                navigate('/formselection');
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // check if the checkbox is true
    const [checked, setChecked] = React.useState(false);
    const handleChecked = (event) => {
        setChecked(event.target.checked);
        setLocation('');
        setTpAmount('');
        setShowDestination(false);
        if (event.target.checked) {
            setCurrencyLabel('USD');
        } else {
            setCurrencyLabel(
                window.location.href.includes('lib') ? 'LRD' : 'LE'
            );
        }
    };

    const today = new Date();

    React.useEffect(() => {
        calculateClaim();
    }, [calculateClaim, reverse, handleReverse]);

    // React.useLayoutEffect(() => {
    //     handleReverse();
    // }, [reverse, handleReverse]);

    // console.log(isValid)

    return (
        <div className="perdiemform">
            <Greeting user={data['First Name']} />
            <form className="mainForm" onSubmit={handleSubmit}>
                <div>
                    <div className="typeChangeButton">
                        <FormLabel id="type-group-label">
                            Request Type
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="type-group-label"
                            row
                            defaultValue={false}
                            name="type"
                            onChange={handleReverse}
                            value={value} // add value prop
                        >
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Advance"
                            />
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Reimbursement"
                            />
                        </RadioGroup>
                    </div>
                    {/* <div className='inputdiv'>
                        <BasicDatePicker
                            dateValue={dateValue}
                            setDateValue={setDateValue}
                        // disablepast={reverse}
                        />
                    </div> */}
                    <div className="inputdiv">
                        <div className="inputdiv">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Departure Date"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                    maxDate={value ? new Date() : undefined} // add maxDate prop
                                    minDate={minDate} // add minDate prop
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="inputdiv">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Return Date"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                    // maxDate={value ? new Date() : undefined} // add maxDate prop
                                    minDate={startDate} // add minDate prop
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className="inputdiv">
                        <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                            <InputLabel id="demo-simple-select-label">
                                Department?
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={department}
                                placeholder="Select One"
                                label="Type of Vehicle"
                                name="deparment"
                                required
                                onChange={(e) => setDepartment(e.target.value)}
                            >
                                {departments.map((el, i) => (
                                    <MenuItem required key={i} value={el}>
                                        {el}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                            <InputLabel id="demo-simple-select-label">
                                Budget Code?
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={budgetCode}
                                placeholder="Select One"
                                label="Type of Vehicle"
                                name="budgetcode"
                                required
                                onChange={(e) => setBudgetCode(e.target.value)}
                            >
                                {budgetCodes[department].map((el, i) => (
                                    <MenuItem
                                        required
                                        key={i}
                                        value={el}
                                        defaultValue={el[0]}
                                    >
                                        {el}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <div className="inputdiv">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleChecked}
                                        name='checked'
                                        color='primary'
                                    />
                                }
                                label='Are you travelling to another country?'
                            />
                            {!checked && !showDestination && (
                                <TextField
                                    // label='From Location'
                                    name='fromLocation'
                                    required
                                    select
                                    SelectProps={{ native: true }}
                                    variant='outlined'
                                    onChange={handleFromLocationChange}
                                    value={fromLocation}
                                >
                                    <option value=''>Select From Location</option>
                                    {uniqueLocations.map((location) => (
                                        <option key={location} value={location}>
                                            {location}
                                        </option>

                                    ))}
                                    <option value="Other">
                                        Other
                                    </option>
                                </TextField>

                            )}
                        </div>
                        {!checked && !showDestination && (
                            <TextField
                                // label='To Location'
                                name='toLocation'
                                required
                                select
                                SelectProps={{ native: true }}
                                variant='outlined'
                                onChange={handleToLocationChange}
                                value={toLocation}
                                disabled={!fromLocation}
                            >
                                <option value=''>Select To Location</option>
                                {uniqueToLocations.map((location) => (
                                    <option key={location} value={location}>
                                        {location}
                                    </option>
                                ))}
                            </TextField>

                        )}
                        {!checked && !showDestination && (
                            <div className='inputdiv'>
                                <TextField
                                    label='Transport Amount'
                                    name='tpamount'
                                    required
                                    InputProps={{ readOnly: true }}
                                    variant='outlined'
                                    value={tpAmount}
                                    onChange={handleChange}
                                />
                            </div>
                        )}

                        {(checked || showDestination) && (
                            <div className='inputdiv'>
                                <TextField
                                    label='From Location'
                                    name='fromLocation'
                                    required
                                    variant='outlined'
                                    onChange={handleChange}
                                    // value={destination}
                                    inputProps={{ maxLength: 100 }}
                                />
                            </div>
                        )}

                        {(checked || showDestination) && (
                            <div className='inputdiv'>
                                <TextField
                                    label='To Location'
                                    name='toLocation'
                                    required
                                    variant='outlined'
                                    onChange={handleChange}
                                    // value={destination}
                                    inputProps={{ maxLength: 100 }}
                                />
                            </div>
                        )}



                        {(showDestination) && (
                            <div className='inputdiv'>
                                <TextField
                                    label='Enter Oneway Transport Amount'
                                    name='addTpAmount'
                                    required
                                    InputProps={{ readOnly: false }}
                                    variant='outlined'
                                    onChange={handleChange}
                                    // value={addTpAmount}
                                    inputProps={{ maxLength: 100 }}
                                />
                            </div>
                        )}
                    </div>
                    <div className="inputdiv">
                        <TextField
                            label="Number of Days"
                            value={days}
                            required
                            name="days"
                            type="number"
                            InputProps={{
                                readOnly: true,
                                min: 1,
                            }}
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="inputdiv radioset" id="accommodation">
                        <FormLabel id="accommodation-group-label">
                            Accommodation
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="accommodation-group-label"
                            defaultValue="No"
                            row
                            onChange={handleChange}
                            name="accommodation"
                        >
                            <FormControlLabel
                                value="Yes"
                                control={<Radio />}
                                label="Yes"
                            />
                            <FormControlLabel
                                value="No"
                                control={<Radio />}
                                label="No"
                            />
                        </RadioGroup>
                    </div>
                    {formData.accommodation === 'Yes' ? (
                        <div className="inputdiv">
                            <TextField
                                label="Number of Nights"
                                value={nights}
                                required
                                name="nights"
                                type="number"
                                InputProps={{
                                    readOnly: true,
                                    min: 0,
                                }}
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </div>
                    ) : null}
                </div>
                <FormLabel id="purpose-group-label">
                    What is the Purpose of your trip?
                </FormLabel>
                <RadioGroup
                    aria-labelledby="purpose-group-label"
                    defaultValue="No"
                    required
                    // onChange={handleChange}
                    onChange={(e) => {
                        setPurpose(e.target.value);
                        handleChange(e); // Call the existing change handler
                    }}
                    name="purpose"
                >
                    <FormControlLabel
                        value="Field Site Visit"
                        control={<Radio />}
                        label="Field/Site Visit"
                    />
                    <FormControlLabel
                        value="Meeting"
                        control={<Radio />}
                        label="Meeting"
                    />
                    <FormControlLabel
                        value="Delivery"
                        control={<Radio />}
                        label="Delivery"
                    />
                    <FormControlLabel
                        value="Other"
                        control={<Radio />}
                        label="Other"
                    />
                </RadioGroup>
                {formData.purpose === 'Other' || formData.purpose === 'Field Site Visit' ||
                    formData.purpose === 'Meeting' || formData.purpose === 'Delivery'
                    ? (
                        <TextField
                            type="text"
                            size="small"
                            label="Please specify"
                            name="other_specify"
                            className="textInput"
                            multiline
                            minRows={2}
                            inputProps={{ maxLength: 100 }}
                            onChange={handleChange}
                        />
                    ) : null}

                <div className="inputdiv">
                    <FormControl>
                        <InputLabel htmlFor="outlined-adornment-amount">
                            Total Claim
                        </InputLabel>
                        <OutlinedInput
                            value={claim.toLocaleString()}
                            name="totalclaim"
                            onChange={handleChange}
                            startAdornment={
                                <InputAdornment position="start">
                                    {currencyLabel}
                                </InputAdornment>
                            }
                            label="Amount"
                        />
                    </FormControl>
                </div>
                {errorpurpose && <p className="error">{errorpurpose}</p>}
                <Button
                    color="primary"
                    variant="outlined"
                    type="submit"
                    size="large"
                    className="submitButton"
                    disabled={!isValid}
                >
                    {loading ? <CircularProgress color="inherit" /> : 'submit'}
                </Button>
            </form>
            <style>{`
        #accommodation{
            display: none;
        }

        .error {
            color: red;
        }

      `}</style>
        </div>
    );
}

export default PerDiem;
