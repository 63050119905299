/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable no-console */
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import budgetCodes, { departments } from './budgetCodes';


function Item({
  // eslint-disable-next-line react/prop-types
  count, data, setData, itemsTotal, sDepartment, currency
}) {
  const [unitCost, setCost] = React.useState(1);
  const [amount, setAmount] = React.useState(1);
  const [itemName, setItemName] = React.useState('');
  const [total, setTotal] = React.useState(0);
  const [description, setInfo] = React.useState('');
  const [budgetCode, setBudgetCode] = React.useState(['select option']);
  const [department] = React.useState('select option');
  const [balance, setBalance] = React.useState(0);
  const [itemTotal, setItemTotal] = React.useState(0);
  

  const handleNameChange = (e) => {
    // check if label is item name
    if (e.target.name === `item${count}`) {
      const input = e.target.value;
      if (input.length <= 20) {
        setItemName(input);
      }
    }
    // also handel amount change here 
    // check if label is Quantity
    if (e.target.name === `amount${count}`) {
      const input = e.target.value;
      if (input >= 0) {
        setAmount(input);
      }
    }
  };

  const handleCostChange = (e) => {
    // make sure input is a positive number
    const input = e.target.value;
    if (input >= 0) {
      setCost(input);
    }

  };



  

  React.useEffect(() => {
    // set total of item by multiplying unit cost by amount of item making sure to convert to number
    const totalAmount = parseInt(unitCost) * parseInt(amount);
    setTotal(totalAmount);
    setData({
      ...data,
      [count]: { 
        budgetcode: budgetCode, 
        name: itemName,
        description,
        cost: unitCost,
        amount,
        total: totalAmount,
        retiement: 0,
        balance,
      },
    });
  }, [
    itemName,
    description,
    amount,
    unitCost,
    total,
    balance,
    itemTotal,
    count,
    data,
    setData,
    itemsTotal,
    sDepartment,
    currency,
    
    

  ]);

  // add total of all items to itemTotal
  React.useEffect(() => {
    let totalAmount = 0;
    for (const item in data) {
      // eslint-disable-next-line react/prop-types
      totalAmount += data[item].total;
    }
    setItemTotal(totalAmount);
  }, [data]);

  // console.log("data", data);
  // console.log("itemTotal", itemTotal);

  return (
    <div className="elDiv">
       <FormControl sx={{ m: 1, minWidth: 200 }} size='small'>
          <InputLabel id='demo-simple-select-label'>
            Budget Code?
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={budgetCode}
            placeholder='Select One'
            label='Type of Vehicle'
            name='budgetcode'
            required
            onChange={(e) => setBudgetCode(e.target.value)}
          >
            <MenuItem value=''>Please select Budget Code</MenuItem>
            {budgetCodes[sDepartment].map((el, i) => (
              <MenuItem key={i} value={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      <TextField
        className="textInput"
        required
        type="text"
        size="small"
        id="outlined-required"
        label="Item Name"
        autoComplete="off"
        value={itemName}
        name={`item${count}`}
        onChange={handleNameChange}
        inputProps={{ maxLength: 20 }}
        helperText='Maximum of 20 characters'
      />
      <TextField
        className="textInput"
        required
        type="text"
        size="small"
        id="outlined-required"
        label="description"
        multiline
        minRows={2}
        name={`item${count}`}
        onChange={(e) => setInfo(e.target.value)}
        inputProps={{ maxLength: 100 }}
        helperText='Maximum of 100 characters'
      />
      <TextField
        className="textInput"
        required
        type="number"
        size="small"
        label="Unit Cost"
        value={unitCost}
        onChange={handleCostChange}
        name={`cost${count}`}
      />

      <TextField
        type="number"
        className="textInput"
        size="small"
        label="Quantity"
        value={amount}
        onChange={(e) =>{
          const input = e.target.value;
          if (input >= 0) {
            setAmount(input);
          }
        }
          
        }
        name={`amount${count}`}
      />
      <TextField
        className="textInput"
        type="number"
        size="small"
        label="Total"
        value={total}
      />
    </div>
  );
}

export default Item;