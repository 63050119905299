import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Greeting from '../components/greeting';
import { useParams, useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    CircularProgress,
} from '@mui/material';

function EditTransportation() {
    const [formData, setFormData] = useState({
        FromLocation: '',
        ToLocation: '',
        oneamount: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        axios.get('https://digital-forms-backend.onrender.com/alltp')
            .then(response => {
                const tpData = response.data.find(tp => tp._id === id);
                if (tpData) {
                    setFormData(tpData);
                } else {
                    setError('Transportation data not found');
                }
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
                setError(error);
                setLoading(false);
            });
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const API_URI = `https://digital-forms-backend.onrender.com/updatetp/${id}`;
        const headers = { 'Content-Type': 'application/json' };

        const updateData = { ...formData };
        delete updateData._id;

        try {
            const response = await axios.put(API_URI, updateData, { headers });
            console.log(response.data);
            window.confirm('You have successfully updated the transportation data.');
            navigate('/viewtp');
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading data!</p>;

    return (
        <div className="formPage">
            <Greeting
                user=' Manager '
                text='Please Update the user data'
            />
            <form onSubmit={handleSubmit} className="form" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>

                <div className="row">
                    <TextField
                        label='From Location'
                        name='FromLocation'
                        value={formData.FromLocation}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="row">
                    <TextField
                        label='To Location'
                        name='ToLocation'
                        value={formData.ToLocation}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="row">
                    <TextField
                        type="number"
                        label='Oneway Amount'
                        name='oneamount'
                        value={formData.oneamount}
                        onChange={handleChange}
                        required
                    />
                </div>
                <Button
                    type='submit'
                    variant='outlined'
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : 'Update'}
                </Button>
            </form>
            <style >{`
        .form {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0;
          margin-bottom: 20px;
        }

        .column {
          display: flex;
          flex-direction: column;
          width: 45%;
          
        }

        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0;
        }

        button {
          margin: 20px 0;
          padding: 10px;
          background-color: #022f8e;
          color: #fff;
          border: none;
          border-radius: 5
        }

        
      `}</style>
        </div>
    );
}

export default EditTransportation;
