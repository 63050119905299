import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { GoogleLogout } from 'react-google-login';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button } from '@mui/material';


function FormSelection() {
    let user = null;
    let department = null;
    if (JSON.parse(localStorage.getItem('userdata')) !== null) {
        const { 'First Name': username, Department } = JSON.parse(
            localStorage.getItem('userdata')
        );
        user = username;
        department = Department;
    }
    // console.log(JSON.parse(localStorage.getItem('userdata'))

    // eslint-disable-next-line no-undef
    // const id = process.env.REACT_APP_CLIENT_ID;



    const navigate = useNavigate();
    const logout = () => {
        window.localStorage.clear();
        navigate('/');
    };

    if (user) {
        return (
            <div className='formPage'>
                <h2>
                    Hello,
                    {user}. Which Request do you want to make today?
                </h2>
                <div className='cardBox'>
                    <Link to='/perdiem' className='formCard perdiem'>
                        Per Diem
                    </Link>

                    <Link to='/pettycash' className='formCard pettycash'>
                        Petty Cash
                    </Link>

                    <Link to='/expenseadvance' className='formCard pettycash'>
                        Expense Advance
                    </Link>

                    <a
                        href='https://docs.google.com/forms/d/e/1FAIpQLSdYBdCJRxM0wtPZ8YuHfrV7PCf0OrvTkYIORhxGjNhdnjSUzA/viewform'
                        rel="nofollow noreferrer"
                        className='formCard vehicle'
                    >
                        Vehicle
                    </a>
                    {(department === "People & Culture" || department === "IT" || department === "Senior Management") && (
                        <Link to='/viewusers' className='formCard viewuser'>
                            View Users
                        </Link>
                    )}

                    {/* {(department === "People & Culture" || department === "IT" || department === "Finance") && ( */}
                    <Link to='/requestsummary' className='formCard vehicle'>
                        Request Report
                    </Link>
                    {/* )} */}

                    {(department === "People & Culture" || department === "IT" || department === "Finance") && (
                        <Link to='/viewtp' className='formCard pettycash'>
                            View Transportation
                        </Link>
                    )}

                </div>
                <footer className='formFooter'>
                    <div>
                        Leave Feedback or Suggestion{' '}
                        <a
                            href='https://docs.google.com/forms/u/1/d/1pwi3OGlRtLydOes6R5YY79one_6QKqxqtvgy24X1ezI/edit'
                            rel="nofollow noreferrer"
                            target="_blank"
                        >
                            HERE
                        </a>
                    </div>
                    <div>
                        <span className='outtext'>
                            Not {user}?
                        </span>
                        <Button
                            variant='contained'
                            size='large'
                            onClick={logout}
                            endIcon={<LogoutIcon />}
                        >
                            LOGOUT
                        </Button>
                    </div>
                </footer>
            </div>
        );
    }

    return (
        <div>
            Please <Link to='/'>LOGIN</Link> to view forms
        </div>
    );
}

export default FormSelection;
