import React, { useState, useEffect } from 'react';

const App = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [status, setStatus] = useState('');
    const [verificationSid, setVerificationSid] = useState('');

    const handleSendOTP = async () => {
        try {
            const response = await fetch('https://digital-forms-backend.onrender.com/send-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phoneNumber,
                }),
            });

            const data = await response.json();
            console.log(data);

            if (response.ok) {
                setStatus('OTP sent successfully');
                setVerificationSid(data.verificationSid);
            } else {
                setStatus(`Failed to send OTP: ${data.message}`);
            }
        } catch (error) {
            setStatus('An error occurred');
            console.error(error);
        }
    };

    const handleVerifyOTP = async () => {
        try {
            const response = await fetch('https://digital-forms-backend.onrender.com/verify-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phoneNumber,
                    otp,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setStatus('OTP verification successful');
                // Perform the login action here
            } else {
                setStatus(`OTP verification failed: ${data.message}`);
            }
        } catch (error) {
            setStatus('An error occurred');
            console.error(error);
        }
    };

    useEffect(() => {
        console.log(verificationSid);
    }, [verificationSid]);

    return (
        <div>
            <h1>Phone Number Login</h1>
            {verificationSid ? (
                <div>
                    <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Enter OTP"
                    />
                    <button onClick={handleVerifyOTP}>Verify OTP</button>
                </div>
            ) : (
                <div>
                    <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Phone number"
                    />
                    <button onClick={handleSendOTP}>Send OTP</button>
                </div>
            )}
            {status && <p>{status}</p>}
        </div>
    );
};

export default App;
